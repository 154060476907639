import React from "react";
import { EducationDataNode, EducationGridProp } from "../../../models/education-grid-params";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../nazi-grid/nazi-grid.scss'

const EducationGrid = (props: EducationGridProp) => {
    const round = (number: number) => {
        return Math.round(number * 100) / 100;
    }
    
    const parseEducationData = () => {
        let result: EducationDataNode[] = [];
        let id = 0;
        for (const key in props.data) {
            result.push({ id: id++, name: key, count: round(props.data[key]) });
            if (props.difference)
                result[result.length - 1]['difference'] = props.difference_data?.[key];
        }
        return result;
    }
    
    const render = () => {
        const data = parseEducationData();
        
        const round = (x: number) => Math.round(x * 100) / 100;
        
        return (
            <>
                <p className="moving_block__line">Образованное население на 2016 год:</p>
                <div>
                    <TableContainer className="scrolling-div" component={ Paper }>
                        <Table sx={ { width: '100%' } } aria-label="simple table">
                            <TableHead>
                                <TableRow sx={ { '& *': { fontWeight: 'bold!important' } } }>
                                    <TableCell sx={ { width: '40%', fontWeight: 'bold' } }>Название</TableCell>
                                    <TableCell align="right">Количество (чел)</TableCell>
                                    { props.difference ?
                                        <TableCell align="right">Количество 2 (чел)</TableCell> : null }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { data.map((row: EducationDataNode) => (
                                    <TableRow
                                        key={ 'education-table-item' + row.name }
                                        sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                    >
                                        <TableCell component="th" scope="row">{ row.name }</TableCell>
                                        <TableCell align="right">{ row.count }</TableCell>
                                        { props.difference ?
                                            round(row.difference) > 0 ?
                                                <TableCell align="right"
                                                           sx={ { color: 'green' } }>{ round(row.difference) }</TableCell>
                                                :
                                                <TableCell align="right"
                                                           sx={ { color: 'red' } }>{ round(row.difference) }</TableCell>
                                            : null }
                                    </TableRow>
                                )) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    }
    
    return render();
}

export default EducationGrid;
