import React from "react";
import './svg-cross.scss'

interface SvgCrossProps {
    SideMenuContainerRef?: React.RefObject<HTMLDivElement>,
    SideMenuContentRef?: React.RefObject<HTMLDivElement>,
    SideMenuHeaderRef?: React.RefObject<HTMLParagraphElement>,
    SideMenuHeaderContainerRef?: React.RefObject<HTMLDivElement>
    SideMenuButtonsContainer?: React.RefObject<HTMLDivElement>
    setCloseSideMenu: any;
    closeSideMenu: boolean;
}

const SvgCross = (props: SvgCrossProps) => {
    let SvgCrossContainer = React.createRef<HTMLDivElement>();
    
    const CloseSideMenu = () => {
        const el = document.getElementById('SideMenuContent');
        el.style.opacity = '1';
        if (props.SideMenuButtonsContainer.current !== null)
            props.SideMenuButtonsContainer.current.style.opacity = '0';
        if (props.SideMenuHeaderRef.current != null)
            props.SideMenuHeaderRef.current.style.opacity = '0';
        setTimeout(() => {
            el.style.display = 'none';
            if (props.SideMenuContentRef.current != null)
                props.SideMenuContentRef.current.style.display = 'none';
            if (SvgCrossContainer.current !== null)
                SvgCrossContainer.current.classList.remove('is_active');
            if (props.SideMenuButtonsContainer.current !== null)
                props.SideMenuButtonsContainer.current.style.display = 'none';
            if (props.SideMenuHeaderRef.current != null)
                props.SideMenuHeaderRef.current.style.display = 'none';
            if (props.SideMenuHeaderContainerRef.current != null)
                props.SideMenuHeaderContainerRef.current.style.justifyContent = 'flex-end';
            if (props.SideMenuContainerRef.current != null)
                props.SideMenuContainerRef.current.style.maxWidth = 'calc(32px * 2 + 40px)';
        }, 300);
    }
    
    const OpenSideMenu = () => {
        const el = document.getElementById('SideMenuContent');
        props.SideMenuContainerRef.current.style.maxWidth = '500px';
        setTimeout(() => {
            el.style.display = 'block';
            if (props.SideMenuContentRef.current !== null)
                props.SideMenuContentRef.current.style.display = 'flex';
            if (props.SideMenuHeaderRef.current !== null)
                props.SideMenuHeaderRef.current.style.display = 'flex';
            if (SvgCrossContainer.current !== null)
                SvgCrossContainer.current.classList.add('is_active');
            if (props.SideMenuButtonsContainer.current != null)
                props.SideMenuButtonsContainer.current.style.display = 'flex';
            if (props.SideMenuHeaderContainerRef.current != null)
                props.SideMenuHeaderContainerRef.current.style.justifyContent = 'space-between';
            setTimeout(() => {
                el.style.opacity = '1';
                if (props.SideMenuButtonsContainer.current != null)
                    props.SideMenuButtonsContainer.current.style.opacity = '1';
                if (props.SideMenuHeaderRef.current != null)
                    props.SideMenuHeaderRef.current.style.opacity = '1';
            });
        }, 300);
        
    }
    
    const InteractWithMenu = () => {
        try {
            props.setCloseSideMenu();
            const el = document.getElementById('SideMenuContent');
            if (el.style.display == 'none')
                OpenSideMenu();
            else
                CloseSideMenu();
        } catch (e: any) {
            console.log(e);
        }
    }
    
    const render = () => {
        return (
            <div ref={ SvgCrossContainer } onClick={ InteractWithMenu }
                 className={ "SvgCrossContainer " + (props.closeSideMenu ? "is_active" : "") }>
                <span className="SvgCrossLine"></span>
                <span className="SvgCrossLine"></span>
            </div>
        );
    }
    
    return render();
}

export default SvgCross;
