// Данные любого административно территориального деления из API
// export interface ObjectData {
//     name: string;
//     data: Data;
// }
//
// export interface Data {
//     birth?:                 number;
//     build?:                 number;
//     extra?:                 { [key: string]: { [key: string]: number } };
//     trade?:                 number;
//     crimes?:                number;
//     events?:                number;
//     nazi?:                  { [key: string]: number };
//     invest?:                number;
//     outlay?:                number;
//     people?:                number;
//     salary?:                number;
//     square?:                number;
//     izbirkom?:              Izbirkom;
//     pol_vozr?:              { [key: string]: { [key: string]: number } };
//     revenues?:              number;
//     services?:              number;
//     education?:             { [key: string]: number };
//     household?:             number;
//     migration?:             number;
//     agriculture?:           number;
//     failed_organizations?:  number;
//     success_organizations?: number;
// }
//
// export interface Izbirkom {
//     party?:               string;
//     count_izbir?:         number;
//     guber_turnout?:       number;
//     gosdum_turnout?:      number;
//     election_results?:    { [key: string]: number };
//     president_turnout?:   number;
//     oblast_dum_turnout?:  number;
//     total_izbir_people?:  number;
//     oblast_sobr_turnout?: number;
// }

import { CommissionMemberData, Document, Documents, Election, ElectionCommission, ElectionCommissionGeneral, ElectionEventData, Member } from "../types/types";

export default class Api {
    public base_url: string;
    public base_assets_url: string;
    
    constructor() {
        // const url = 'https://back.maps.tw1.ru/';
        const url = 'https://xn--90agcayt.xn--b1amrheca2if.xn--p1ai/';
        // const url = 'http://127.0.0.1:8000/'
        this.base_url = `${ url }api/`;
        this.base_assets_url = `${ url }assets/`;
    }
    
    get_endpoint(endpoint: string, data: string = '') {
        if (data == '')
            return this.base_url + endpoint + "/";
        else
            return this.base_url + endpoint + '/?' + data;
    }
    
    get_assets_endpoint(endpoint: string, data: string = '') {
        if (data == '')
            return this.base_assets_url + endpoint;
        else
            return this.base_assets_url + endpoint;
    }
    
    async get_all_districts(): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('district/all'));
            return res.text();
        } catch (e) {
            console.error(`Error: get_all_districts ${e}`);
        }
    }
    
    async get_all_regions(): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('region/all'));
            return res.text();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }
    
    async get_all_municipalities(): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('municipality/all'));
            return res.text();
        } catch (e) {
            console.error(`Error: get_all_municipalities ${e}`);
        }
    }
    
    async get_all_izbirkoms(): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('izbirkom/all'));
            return res.text();
        } catch (e) {
            console.error(`Error: get_all_izbirkoms ${e}`);
        }
    }
    
    async get_country(): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('country'));
            return res.text();
        } catch (e) {
            console.error(`Error: get_country ${e}`);
        }
    }
    
    async get_district(name: string): Promise<string> {
        try {
            let res = await fetch(this.get_endpoint('district', `name=${ name }`));
            if (res.status == 404) {
                return '<';
            }
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_region(name: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('region', `name=${ name }`));
            if (res.status == 404) {
                return '<';
            }
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_municipality(name: string, region: string): Promise<string> {
        try {
            let res: any;
            if (name == 'г. Москва' || name == 'г. Санкт-Петербург' || name == 'г. Севастополь')
                res = await fetch(this.get_endpoint('region', `name=${ name }`));
            else
                res = await fetch(this.get_endpoint('municipality', `name=${ name }&region=${ region }`));
            if (res.status == 404) {
                return '<';
            }
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_izbirkom(name: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('izbirkom', `name=${ name }`));
            if (res.status == 404) {
                return '<';
            }
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_country_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('country.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`!Error: ${e}`);
        }
    }
    
    async get_districts_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('districts.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`Error get_districts_geojson: ${e}`);
        }
    }
    
    async get_regions_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('regions.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`Error get_regions_geojson: ${e}`);
        }
    }
    
    async get_municipalities_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('municipalities.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`Error get_municipalities_geojson: ${e}`);
        }
    }
    
    async get_izbirkom_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('izbirkom.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`Error get_izbirkom_geojson: ${e}`);
        }
    }
    
    async get_division_geojson(): Promise<ArrayBuffer> {
        try {
            const res = await fetch(this.get_assets_endpoint('division.pbf'));
            return res.arrayBuffer();
        } catch (e) {
            console.error(`Error get_division_geojson: ${e}`);
        }
    }
    
    async get_district_temperature(cat: string, param: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('district/temperature', `cat=${ cat }&param=${ param }`));
            return res.text();
        } catch (e) {
            console.error(`Error get_district_temperature: ${e}`);
        }
    }
    
    async get_region_temperature(cat: string, param: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('region/temperature', `cat=${ cat }&param=${ param }`));
            return res.text();
        } catch (e) {
            console.error(`Error get_region_temperature: ${e}`);
        }
    }
    
    async get_region_presentation(name: string, compare_name: string = ''): Promise<string> {
        try {
            let res: any;
            if (compare_name === '')
                res = await fetch(this.get_endpoint('region/presentation', `name=${ name }`));
            else
                res = await fetch(this.get_endpoint('region/presentation', `name=${ name }&name2=${ compare_name }`));
            return res.text();
        } catch (e) {
            console.error(`Error get_region_presentation: ${e}`);
        }
    }
    
    async get_district_presentation(name: string, compare_name: string = ''): Promise<string> {
        try {
            let res: any;
            if (compare_name === '')
                res = await fetch(this.get_endpoint('district/presentation', `name=${ name }`));
            else
                res = await fetch(this.get_endpoint('district/presentation', `name=${ name }&name2=${ compare_name }`));
            return res.text();
        } catch (e) {
            console.error(`Error get_district_presentation: ${e}`);
        }
    }

    async getAllElection(): Promise<Election[]> {
        try {
            const res = await fetch(this.get_endpoint('election/all'));
            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getElection(value: string): Promise<ElectionEventData> {
        try {
            const par = encodeURIComponent(value);
            const res = await fetch(this.get_endpoint(`election`, `name=${par}`));
            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getElectionCommissions(): Promise<ElectionCommissionGeneral[]> {
        try {
            const res = await fetch(this.get_endpoint(`ec/all`));
            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getElectionCommission(value: string): Promise<ElectionCommission> {
        try {
            const par = encodeURIComponent(value);
            const res = await fetch(this.get_endpoint(`ec`, `name=${par}`));
            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getAllDocuments(page: number): Promise<Documents> {
        try {
            const res = await fetch(this.get_endpoint(`news`, `page=${page}`));
            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getSearchDocuments(value: string): Promise<Document[]> {
        try {
            const par = encodeURIComponent(value);
            const res = await fetch(this.get_endpoint(`news/search`, `query=${par}`));

            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getSearchDocumentsByMember(memberId: number): Promise<CommissionMemberData> {
        try {
            const res = await fetch(this.get_endpoint(`news/search/member/${memberId}`));

            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }

    async getSearchMembers(value: string): Promise<Member[]> {
        try {
            const par = encodeURIComponent(value);
            const res = await fetch(`${this.base_url}ec/members/search?query=${par}`);

            return await res.json();
        } catch (e) {
            console.error(`Error: get_all_regions ${e}`);
        }
    }
}
