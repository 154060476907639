import React, { Dispatch, FC, SetStateAction } from "react";
import './election-module.scss';
import { ReactSVG } from "react-svg";
import { Election, ElectionModuleMode } from "../../types/types";
import ElectionsTab from "../elections-tab";
import BankDocumentsTab from "../bank-documents-tab";

interface MenuList {
  id: number,
  title: string,
  description: string,
  image: string,
  type: ElectionModuleMode,
};

const menuList: MenuList[] = [
  {
    id: 0,
    title: "Выборы",
    description: "Информация о выборах президента, в городскую думу, а также выборах глав субъектов",
    image: "elections",
    type: "elections-tab",
  },
  {
    id: 1,
    title: "Карта границ",
    description: "Карта границ избирательного устройства РФ с внутренним делением ",
    image: "map",
    type: "boundary-map-tab",
  },
  {
    id: 2,
    title: "Банк документов",
    description: "Все документы избирательных комиссий МО с возможностью фильтрации и поиска",
    image: "documents",
    type: "documents-tab",
  },
  {
    id: 3,
    title: "ТИК/УИК",
    description: "Местоположение ТИК/УИК на карте, а также дополнительная информация о комиссии",
    image: "commissions",
    type: "commissions-tab",
  },
];

interface SelectorModuleProps {
  electionModuleMode: ElectionModuleMode;
  setElectionModuleMode: Dispatch<SetStateAction<ElectionModuleMode>>;
  allElection: Election[];
  //
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
  mapRef: any;
  popup: any;
  setIsShowElectionPoints: Dispatch<(prevState: boolean) => undefined>;
};

export const ElectionModule: FC<SelectorModuleProps> = ({ electionModuleMode, setElectionModuleMode, allElection, mapRef, popup, setIsLoadingElectionPoints, setIsShowElectionPoints }) => {
  const handleClickBack = () => {
    setElectionModuleMode("main");
  };

  return (
    <div className="selector-module-container">
      {electionModuleMode === "main" && (
        <>
          <h1 className="selector-module-title">
            Избирательный модуль
          </h1>
          <h2 className="selector-module-description">
            Вы находитесь в избирательном модуле проекта всяроссия.рф
            Ниже вы можете найти статистику о различных выборах, подробную информацию об избирательных комиссиях, а также ознакомиться с картой избирательного устройства РФ  
          </h2>
          <ul className="selector-module-list-container">
            {menuList.map((item) => (
              <li onClick={() => setElectionModuleMode(item.type)} className="selector-module-list-item" key={item.id}>
                <h3 className="selector-module-list-item-title">{item.title}</h3>
                <p className="selector-module-list-item-description">{item.description}</p>
                <div className="list-item-icon-wrapper">
                  <ReactSVG src={`/images/${item.image}.svg`} height="152px" width="152px" />
                </div>
              </li>
            ))}
          </ul>
          <img alt="map" width={1440} height={789} className="image-map" src="/images/map.png" />
        </>
      )}
      {electionModuleMode === "elections-tab" && (
        <ElectionsTab allElection={allElection} handleClickBack={handleClickBack} />
      )}
      {electionModuleMode === "documents-tab" && (
        <BankDocumentsTab
          handleClickBack={handleClickBack}
          setIsLoadingElectionPoints={setIsLoadingElectionPoints}
          mapRef={mapRef}
          popup={popup}
          setIsShowElectionPoints={setIsShowElectionPoints}
        />
      )}
    </div>
  );
};
