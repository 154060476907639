// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LevelMenuContainer {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: absolute;
  top: 100px;
  right: 10px;
  border-radius: 5px;
  border: 2px solid gray;
  overflow: hidden;
}
.LevelMenuContainer label {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.LevelMenuContainer label span {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.LevelMenuContainer label span:after {
  content: "";
  position: absolute;
  display: none;
  left: 12.5px;
  top: 10px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.LevelMenuContainer label input:checked ~ span {
  background-color: #2196F3;
  box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);
}
.LevelMenuContainer label input:checked ~ span:after {
  display: block;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/level-menu/level-menu.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,MAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AACJ;AACI;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,iCAAA;EACA,wCAAA;AACN;AACM;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,wBAAA;AACR;AAGI;EACE,yBAAA;EACA,6CAAA;AADN;AAGM;EACE,cAAA;EACA,YAAA;AADR","sourcesContent":[".LevelMenuContainer {\n  height: fit-content;\n  display: flex;\n  flex-direction: column;\n  gap: 0;\n  position: absolute;\n  top: 100px;\n  right: 10px;\n  border-radius: 5px;\n  border: 2px solid gray;\n  overflow: hidden;\n\n  label {\n    width: 30px;\n    height: 30px;\n    display: inline-block;\n    position: relative;\n    cursor: pointer;\n    user-select: none;\n\n    span {\n      position: absolute;\n      top: 0;\n      left: 0;\n      height: 30px;\n      width: 30px;\n      background-color: #eee;\n      transition: background-color 0.3s;\n      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n\n      &:after {\n        content: \"\";\n        position: absolute;\n        display: none;\n        left: calc((30px - 5px) / 2);\n        top: calc((30px - 10px) / 2);\n        width: 5px;\n        height: 10px;\n        border: solid white;\n        border-width: 0 3px 3px 0;\n        transform: rotate(45deg);\n      }\n    }\n\n    input:checked ~ span {\n      background-color: #2196F3;\n      box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);\n\n      &:after {\n        display: block;\n        height: 10px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
