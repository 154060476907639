import React from "react";
import './level-menu.scss'

interface LevelMenuProps {
    level: number;
    changeLevel: any;
}

const LevelMenu  = (props: LevelMenuProps) => {
    let CheckboxChecked: boolean = false;
    
    const render = () => {
        return (
            <div className="LevelMenuContainer">
                <section className="flex-col">
                    <label className="checkbox-container">
                        <input className="custom-checkbox" onChange={ () => props.changeLevel(0) }
                               checked={ props.level == 0 } type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container">
                        <input className="custom-checkbox" onChange={ () => props.changeLevel(1) }
                               checked={ props.level == 1 } type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container">
                        <input className="custom-checkbox" onChange={ () => props.changeLevel(2) }
                               checked={ props.level == 2 } type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container">
                        <input className="custom-checkbox" onChange={ () => props.changeLevel(3) }
                               checked={ props.level == 3 } type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                </section>
            </div>
        );
    }
    
    return render();
}

export default LevelMenu;
