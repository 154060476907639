// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector-module-back-button {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #FFFFFF;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.031372549);
  cursor: pointer;
}

.selector-module-icon {
  height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/back-button/back-button.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,sDAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".selector-module-back-button {\n  position: absolute;\n  z-index: 10;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  background-color: #FFFFFF;\n  border-radius: 50%;\n  border: none;\n  box-shadow: 0px 0px 7px 2px #00000008;\n  cursor: pointer;\n}\n\n.selector-module-icon {\n  height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
