// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background: #f5f6f8;
  z-index: 1;
}

.side-menu-container {
  overflow-y: scroll;
  max-width: 100vw;
  height: 100vh;
  width: -moz-fit-content;
  width: fit-content;
  background: #fff;
  z-index: 100;
}

canvas {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;AAAF;;AAGA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,sBAAA;AAAF","sourcesContent":["\n.MainContainer {\n  width:          100%;\n  height:         100vh;\n  display:        flex;\n  flex-direction: row;\n  position:       relative;\n  background:     #f5f6f8;\n  z-index: 1;\n}\n\n.side-menu-container {\n  overflow-y: scroll;\n  max-width: 100vw;\n  height:    100vh;\n  width:     fit-content;\n  background: #fff;\n  z-index: 100;\n}\n\ncanvas {\n  width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
