import React, { ChangeEvent, FC } from "react";
import "./input.scss";

interface SelectorProps {
  value: string | number;
  onChange: (event: string) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const Input: FC<SelectorProps> = ({ onChange, value, className, disabled = false, placeholder }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <input className={`main-input ${className}`} placeholder={placeholder} value={value} onChange={handleChange} />
  );
};
