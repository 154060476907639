// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  display: flex;
  gap: 10px;
  align-items: center;
}

.popup-list-container {
  max-height: 210px;
  overflow-y: auto;
}

.strong {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/show-elections-commissions/show-elections-commissions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".input {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.popup-list-container {\n  max-height: 210px;\n  overflow-y: auto;\n}\n\n.strong {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
