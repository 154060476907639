import React from "react";

import './cross-menu.scss'

interface CrossMenuProps {
    change_state: any;
    change_compare_mode: any;
    compare_mode: boolean
}

const CrossMenu = (props: CrossMenuProps) => {
    let difference: boolean = props.compare_mode;
    let ContainerRef = React.createRef<HTMLDivElement>();
    let Line1 = React.createRef<HTMLDivElement>();
    let Line2 = React.createRef<HTMLDivElement>();
    
    const TurnOn = () => {
        ContainerRef.current.style.transform = 'rotate(45deg)';
        Line1.current.style.background = '#d50f0f';
        Line2.current.style.background = '#d50f0f';
        
        props.change_state();
        props.change_compare_mode(true);
    }
    
    const TurnOff = () => {
        ContainerRef.current.style.transform = 'rotate(0deg)';
        Line1.current.style.background = '#2a5cdc';
        Line2.current.style.background = '#2a5cdc';
        
        props.change_state();
        props.change_compare_mode(false);
    }
    
    const Interact = () => {
        if (difference)
            TurnOff();
        else
            TurnOn();
        difference = !difference;
    }
    
    const render = () => {
        const ContainerStyles = {
            transform: 'rotate(45deg)',
        }
        const LineStyles = {
            background: '#d50f0f',
        }
        return (
            <div onClick={ Interact } className="CrossMenuContainer">
                <div style={ { ...(props.compare_mode && ContainerStyles) } } ref={ ContainerRef }
                     className="CrossMenuContent">
                    <div style={ { ...(props.compare_mode && LineStyles) } } ref={ Line1 }
                         className="line"></div>
                    <div style={ { ...(props.compare_mode && LineStyles) } } ref={ Line2 }
                         className="line"></div>
                </div>
                <p>
                    { props.compare_mode ? 'Выйти из режима сравнения' : 'Добавить территорию к сравнению' }
                </p>
            </div>
        );
    }
    
    return render();
}

export default CrossMenu;
