import React, { FC } from "react";
import { ReactSVG } from "react-svg";
import "./back-button.scss";

interface BackButtonProps {
  onClick: () => void;
  className?: string;
}

export const BackButton: FC<BackButtonProps> = ({ onClick, className }) => {
  return (
    <button className={`selector-module-back-button ${className}`} onClick={onClick}>
      <ReactSVG className="selector-module-icon" src={`/images/arrow.svg`} />
    </button>
  );
};
