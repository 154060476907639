// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SvgCrossContainer {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  background: #2A5CDC;
  position: relative;
  cursor: pointer;
}

.SvgCrossLine {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 3px;
  background: white;
  transform-origin: center;
  transition-duration: 0.3s;
}

.SvgCrossLine:nth-child(1) {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.SvgCrossLine:nth-child(2) {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.SvgCrossContainer.is_active .SvgCrossLine:nth-child(1) {
  transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);
}

.SvgCrossContainer.is_active .SvgCrossLine:nth-child(2) {
  transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);
}`, "",{"version":3,"sources":["webpack://./src/components/svg-cross/svg-cross.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;EACA,wBAAA;EACA,yBAAA;AACF;;AAEA;EACE,0DAAA;AACF;;AACA;EACE,2DAAA;AAEF;;AACA;EACE,qEAAA;AAEF;;AAAA;EACE,qEAAA;AAGF","sourcesContent":[".SvgCrossContainer {\n  display: flex;\n  width: 40px;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  border-radius: 100%;\n  background: #2A5CDC;\n  position: relative;\n  cursor: pointer;\n}\n\n.SvgCrossLine {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 20px;\n  height: 3px;\n  background: white;\n  transform-origin: center;\n  transition-duration: .3s;\n}\n\n.SvgCrossLine:nth-child(1) {\n  transform: translateX(-50%) translateY(-50%) rotate(45deg);\n}\n.SvgCrossLine:nth-child(2) {\n  transform: translateX(-50%) translateY(-50%) rotate(-45deg);\n}\n\n.SvgCrossContainer.is_active .SvgCrossLine:nth-child(1) {\n  transform: translateX(-50%) translateY(calc(-50% + 3px)) rotate(0deg);\n}\n.SvgCrossContainer.is_active .SvgCrossLine:nth-child(2) {\n  transform: translateX(-50%) translateY(calc(-50% - 3px)) rotate(0deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
