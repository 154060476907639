import React, { Component } from "react";
import './top-menu.scss'

interface TopMenuProps {
    type: boolean;
    setShowType: any;
}

const TopMenu = (props: TopMenuProps) => {
    return (
        <div className="TopMenuContainer">
            <div className="flex-row-rev just-space w-full align-center gap-10">
                <input onChange={() => props.setShowType(false)} checked={!props.type} type={ "checkbox" }/>
                <p>Административно-территориальное устройство</p>
            </div>
            <div className="flex-row-rev just-space w-full align-center gap-10">
                <input onChange={() => props.setShowType(true)} checked={props.type} type={ "checkbox" }/>
                <p>Избирательное устройство</p>
            </div>
        </div>
    );
}

export default TopMenu;
