import React, { FC, PropsWithChildren } from "react";
import "./primary-button.scss";

interface PrimaryButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const PrimaryButton: FC<PropsWithChildren<PrimaryButtonProps>> = ({ onClick, children, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className={`primary-button ${disabled ? "disabled-primary-button" : ""}`}>
      {children}
    </button>
  );
};
