import { CountriesColors, RegionParent } from "./countries-colors";

export function parseGeojson(_level: number, resp: any) {
    function get_name(_level: number, properties: any) {
        switch (_level) {
            case(0):
                return properties.name;
            case(1):
                return properties.name;
            case(2):
                return properties.admin_l3;
            case(3):
                if (properties.name === 'г. Москва' || properties.name === 'г. Санкт-Петербург' || properties.name === 'г. Севастополь')
                    return RegionParent[properties.name]
                return RegionParent[properties.a_rgn];
            case(4):
                return RegionParent[properties.a_rgn];
            case(5):
                return RegionParent[properties.a_rgn];
        }
    }
    
    let data = resp;
    for (const regionKey in data.features)
        data.features[regionKey].properties.color = CountriesColors[get_name(_level, data.features[regionKey].properties)];
    
    // if (_level === 2) {
    //     let json = {};
    //     for (const region in data.features) {
    //         json[data.features[region].properties.name] = data.features[region].properties.admin_l3;
    //     }
    //     let res = '';
    //     for (const jsonKey in json) {
    //         res += `"${jsonKey}": "${json[jsonKey]}",\n`
    //     }
    //     console.log(res);
    // }
    
    
    return data;
}

export function setTemperature(temperature: any, geojson: any) {
    let min = 0, max = 0;
    const get_color = (x: number) => {
        if (x > 0)
            return `rgb(0,${ x / max * 255 },0)`;
        else if (x < 0)
            return `rgb(${ x / min * 255 },0,0)`;
        else
            return 'rgb(255,255,0)';
    }
    
    for (const key in temperature) {
        let number = Math.round((Number(temperature[key])) * 1000) / 1000;
        console.log(number);
        min = Math.min(min, number);
        max = Math.max(max, number);
    }
    
    let result = geojson;
    for (const region in result.features) {
        let found = false;
        for (const key in temperature) {
            let number = Math.round((Number(temperature[key]) - 100) * 100) / 100
            if (result.features[region].properties.name === key) {
                result.features[region].properties.color = get_color(number);
                found = true;
                break;
            }
        }
        if (!found)
            result.features[region].properties.color = '#333333';
    }
    return result;
}

export function getNameByLevel(level: number, showType: boolean): string {
    if (showType && level === 3) return 'izbirkoms';
    if (!showType && level === 4) return 'municipalities';
    
    if (level === 0) return 'country'
    if (level === 1) return 'districts';
    if (level === 2) return 'regions';
    if (level === 3) return 'municipalities'
    return 'izbirkoms';
}

export const toggleBarsLayersIds = [
    'country',
    'districts',
    'regions',
    'municipalities',
    'izbirkoms',
]

export const addingLayerName = [
    { outline: 'adding-layer-region-outline', fill: 'adding-layer-region-fill' },
    {
        outline: 'adding-layer-3-outline',
        fill: 'adding-layer-3-fill',
        background: 'adding-layer-3-background',
        background_outline: 'adding-layer-3-background-outline',
        added_bg: 'adding-layer-3-fill-outline'
    },
]
