import React from "react";
import { OpenMenuProp } from "../../models/open-menu-models";
import { TableDataNames, TableDataUnits, TableDataYears } from "../../models/table-data-params";
import { EducationGrid, ExtraDataMenu, NaziGrid, PolVozrGrid } from "../";
import ElectionResultsGrid from "../grids/election-results-grid";
import './open-menu.scss'
import ShowElectionsCommissions from "../show-elections-commissions";
import DocumentBankGrid from "../grids/document-bank-grid";

const OpenMenu = (props: OpenMenuProp) => {
    let ContainerRef = React.createRef<HTMLDivElement>();
    let HeaderRef = React.createRef<HTMLDivElement>();
    let SvgRef = React.createRef<SVGSVGElement>();
    let naziGridDisplay: boolean = false;
    let educationGridDisplay: boolean = false;
    let polVozrGridDisplay: boolean = false;
    let electionDisplay: boolean = false;
    let extraDataDisplay: boolean = false;

    const getTableItems = () => {
        let result = [];
        for (const key in props.data) {
            if (key === 'nazi')
                naziGridDisplay = true;
            else if (key === 'education')
                educationGridDisplay = true;
            else if (key === 'pol_vozr')
                polVozrGridDisplay = true;
            else if (key === 'extra')
                extraDataDisplay = true;
            else if (key === 'election_results')
                electionDisplay = true;
            else if (key === 'total_izbir_people' || (key === 'count_izbir' && props.level !== 4))
                result.push(
                    <p key={ 'p_element' + TableDataNames[key] } className="moving_block__line">
                        { TableDataNames[key] }: <span>{ props.data[key] + ' ' + TableDataUnits[key] }</span> { GetDifferenceElement(props.data[key], 'izbirkom', key) }
                    </p>
                )
            else if (props.level !== 4)
                result.push(
                    <p key={ 'p_element' + TableDataNames[key] } className="moving_block__line">
                        { TableDataNames[key] } { TableDataYears[key] !== '' ? `на ${ TableDataYears[key] } год` : '' }: <span>{ props.data[key] + ' ' + TableDataUnits[key] }</span> { GetDifferenceElement(props.data[key], key) }
                    </p>
                )
        }
        return result;
    }
    
    const GetDifferenceElement = (_data: number, key1: any, key2: any = null, key3: any = null) => {
        const round = (x: number) => Math.round(x * 100) / 100;
        if (!props.difference) return null;
        let data = props.difference_data?.[key1];
        if (key3 != null)
            data = props.difference_data?.[key1]?.[key2]?.[key3]
        else if (key2 != null)
            data = props.difference_data?.[key1]?.[key2]
        return <><span className="light-blue">{ data }</span> / <span
            className={ round(_data - data) < 0 ? 'difference_span__lower' : 'difference_span__upper' }>{ round(_data - data) }</span></>
    }
    
    const CloseMenu = () => {
        props.setOpenMenuState(props.openMenuIndex, false);
        ContainerRef.current.style.maxHeight = '0';
        HeaderRef.current.style.borderBottom = '1px solid #f5f6f8';
        SvgRef.current.style.transform = 'rotate(180deg)';
        setTimeout(() => {
            ContainerRef.current.style.paddingTop = '0';
            ContainerRef.current.style.paddingBottom = '0';
        }, 300);
    }
    
    const OpenMenu = () => {
        props.setOpenMenuState(props.openMenuIndex, true);
        ContainerRef.current.style.paddingTop = '15px';
        ContainerRef.current.style.paddingBottom = '15px';
        ContainerRef.current.style.maxHeight = '300vh';
        HeaderRef.current.style.borderBottom = '1px solid #c7c7c7';
        SvgRef.current.style.transform = 'rotate(0)';
    }
    
    const Interact = () => {
        if (ContainerRef.current.style.maxHeight != '300vh')
            OpenMenu();
        else
            CloseMenu();
    }
    
    const render = () => {

        function displayNaziGrid() {
            if (!naziGridDisplay)
                return null;
            return <NaziGrid
                data={ props.data['nazi'] }
                difference={ props.difference }
                difference_data={ props.difference_data?.['nazi'] }
            />
        }
        
        function displayEducationGrid() {
            if (!educationGridDisplay)
                return null;
            return <EducationGrid
                data={ props.data['education'] }
                difference={ props.difference }
                difference_data={ props.difference_data?.['education'] }
            />
        }
        
        function displayPolVozrGrid() {
            if (!polVozrGridDisplay)
                return null;
            return <PolVozrGrid
                data={ props.data['pol_vozr'] }
                difference={ props.difference }
                difference_data={ props.difference_data?.['pol_vozr'] }
            />
        }

        function displayBankDocumentGrid() {
            if (props.name !== 'Банк документов') {
                return null;
            }

            return (
                <DocumentBankGrid
                    mapRef={ props.mapRef }
                    regions_data={ props.regions_data }
                    data={ props.data }
                    level={ props.level }
                    change_temperature={ props.change_temperature }
                    change_temperature_param={ props.change_temperature_param }
                    temp_mode={ false }
                    temperature_param={ props.temperature_param }
                    temp_open_menu={ props.temp_open_menu }
                    setTempOpenMenu={ props.setTempOpenMenu }
                    allElection={props.allElection}
                    selectedElectionEvent={props.selectedElectionEvent}
                    setSelectedElectionEvent={props.setSelectedElectionEvent}
                    handleSelectElEvent={props.handleSelectElEvent}
                    selectedElectionEventData={props.selectedElectionEventData}
                    selectedElectionCandidate={props.selectedElectionCandidate}
                    handleSelectElCandidate={props.handleSelectElCandidate}
                    nameRegion={props.nameRegion}
                    isShowElectionPoints={props.isShowElectionPoints}
                    setIsShowElectionPoints={props.setIsShowElectionPoints}
                    activeMapLayerId={props.activeMapLayerId}
                    isLoadingElectionPoints={props.isLoadingElectionPoints}
                    setIsLoadingElectionPoints={props.setIsLoadingElectionPoints}
                    popup={props.popup}
                />
            )
        }
        
        function displayElectionGrid() {
            if (!electionDisplay)
                return null;
            if (Object.keys(props.difference_data).length === 0)
                return <ElectionResultsGrid
                    data={ props.data['election_results'] }
                    difference={ props.difference }
                    difference_data={ null }
                />
            return <ElectionResultsGrid
                data={ props.data['election_results'] }
                difference={ props.difference }
                difference_data={ props.difference_data?.['izbirkom']?.['election_results'] }
            />
        }
        
        const HeaderStyle = {
            borderBottom: '1px solid #c7c7c7',
        };
        const SvgStyle = {
            transform: 'rotate(0)',
        };
        const ContainerStyle = {
            paddingTop: '15px',
            paddingBottom: '15px',
            maxHeight: '300vh',
        };
        
        return (
            <div className="moving_block">
                <div style={ { ...(props.openMenuState && HeaderStyle) } } ref={ HeaderRef }
                     onClick={ Interact } className="moving_block__header">
                    <p>{ props.name }</p>
                    <svg style={ { ...(props.openMenuState && SvgStyle) } } ref={ SvgRef } width="16"
                         height="8" viewBox="0 0 16 8"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.05687 -4.25131e-05L15.365 7.54541L12.3676 7.54541L7.99898 1.97723L8.1655 2.03405L7.7443 2.03405L7.92063 1.97723L3.54215 7.54541L0.554621 7.54541L6.86274 -4.2705e-05L9.05687 -4.25131e-05Z"
                            fill="black"/>
                    </svg>
                </div>
                <div style={ { ...(props.openMenuState && ContainerStyle) } } ref={ ContainerRef }
                     className="moving_block__content">
                    {/* {props.name === 'Избирательные показатели' && (
                        <ShowElectionsCommissions
                            mapRef={ props.mapRef }
                            regions_data={ props.regions_data }
                            data={ props.data }
                            level={ props.level }
                            change_temperature={ props.change_temperature }
                            change_temperature_param={ props.change_temperature_param }
                            temp_mode={ false }
                            temperature_param={ props.temperature_param }
                            temp_open_menu={ props.temp_open_menu }
                            setTempOpenMenu={ props.setTempOpenMenu }
                            allElection={props.allElection}
                            selectedElectionEvent={props.selectedElectionEvent}
                            setSelectedElectionEvent={props.setSelectedElectionEvent}
                            handleSelectElEvent={props.handleSelectElEvent}
                            selectedElectionEventData={props.selectedElectionEventData}
                            selectedElectionCandidate={props.selectedElectionCandidate}
                            handleSelectElCandidate={props.handleSelectElCandidate}
                            nameRegion={props.nameRegion}
                            isShowElectionPoints={props.isShowElectionPoints}
                            setIsShowElectionPoints={props.setIsShowElectionPoints}
                            activeMapLayerId={props.activeMapLayerId}
                            isLoadingElectionPoints={props.isLoadingElectionPoints}
                            setIsLoadingElectionPoints={props.setIsLoadingElectionPoints}
                            popup={props.popup}
                        />
                    )} */}
                    { getTableItems().map(el => el) }
                    
                    { displayNaziGrid() }
                    { displayEducationGrid() }
                    { displayPolVozrGrid() }
                    {/* { displayBankDocumentGrid() } */}
                    { displayElectionGrid() }
                    { !extraDataDisplay ? null :
                        <ExtraDataMenu
                            mapRef={ props.mapRef }
                            regions_data={ props.regions_data }
                            data={ props.data }
                            level={ props.level }
                            change_temperature={ props.change_temperature }
                            change_temperature_param={ props.change_temperature_param }
                            temp_mode={ false }
                            temperature_param={ props.temperature_param }
                            temp_open_menu={ props.temp_open_menu }
                            setTempOpenMenu={ props.setTempOpenMenu }
                            allElection={props.allElection}
                            selectedElectionEvent={props.selectedElectionEvent}
                            setSelectedElectionEvent={props.setSelectedElectionEvent}
                            handleSelectElEvent={props.handleSelectElEvent}
                            selectedElectionEventData={props.selectedElectionEventData}
                            selectedElectionCandidate={props.selectedElectionCandidate}
                            handleSelectElCandidate={props.handleSelectElCandidate}
                            nameRegion={props.nameRegion}
                            popup={props.popup}
                        />
                    }
                </div>
            </div>
        );
    }
    
    return render();
}

export default OpenMenu;
