import React from "react";

import './loader.scss'

interface CrossMenuProps {
    ContainerRef: any;
}

const Loader = (props: CrossMenuProps) => {
    return (
        <>
            <div className="spinner-container" ref={ props.ContainerRef }>
                <div className="spinner center">
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                    <div className="spinner-blade"></div>
                </div>
            </div>
        </>
    );
}

export default Loader;
