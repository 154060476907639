import React, { useEffect, useRef, useState } from "react";
import './app.scss';
import { MapProject, SideMenu } from "./components";
import Api from "./api/api";
import { addingLayerName, toggleBarsLayersIds } from "./models/utils";
import { Election, ElectionEventData, ElectionModuleMode } from "./types/types";
import ElectionModule from "./components/election-module";
import ElectionCommissionControllers from "./components/election-commission-controllers";

interface AppProps {
    all_data: any[];
}

const App = (props: AppProps) => {
    const [showType, setShowType] = useState(false);
    const [tempMode, setTempMode] = useState(false);
    const [popupMode, setPopupMode] = useState(false);
    const [popupsData, setPopupsData] = useState(63);
    const popup = useRef(null);
    const electionPointPopup = useRef(null);
    const [render_key, setRenderKey] = useState(0);
    
    const [map_render_key, setMapRenderKey] = useState(0);
    const mapRef = useRef(null);
    const activeMapLayerId = useRef(null);
    const levelBeforeTempSwitch = useRef(null);
    const isFirstTempModeChange = useRef(true);
    const api = new Api();
    
    const [closeSideMenu, setCloseSideMenu] = useState(false);
    const [openMenuState, setOpenMenuState] = useState([false, false, false]);
    
    let [name, setName] = useState('Центральный федеральный округ');
    let [region, setRegion] = useState('');
    let [compare_mode, setCompareMode] = useState(false);
    let [compare_name, setCompareName] = useState("Уральский федеральный округ");
    let [compare_region, setCompareRegion] = useState("");
    let [level, setLevel] = useState(1);
    let [last_name, setLastName] = useState('');
    let [last_region, setLastRegion] = useState('');
    let [last_compare_name, setLastCompareName] = useState('');
    let [last_compare_region, setLastCompareRegion] = useState('');
    let [temperature, setTemperature] = useState(null);
    let [temperature_param, setTemperatureParam] = useState(null);
    let [temp_open_menu, setTempOpenMenu] = useState<string | number>(0);
    const [allElection, setAllElection] = useState<Election[]>([]);
    const [selectedElectionEvent, setSelectedElectionEvent] = useState<string>();
    const [selectedElectionEventData, setSelectedElectionEventData] = useState<ElectionEventData>();
    const [selectedElectionCandidate, setSelectedElectionCandidate] = useState<string | null>(null);
    const [isShowElectionPoints, setIsShowElectionPoints] = useState<boolean>();
    const [isLoadingElectionPoints, setIsLoadingElectionPoints] = useState(false);
    const [uiMode, setUiMode] = useState<"map" | "election-module">('map');
    const [electionModuleMode, setElectionModuleMode] = useState<ElectionModuleMode>("main");

    const [open_menus_data, setOpenMenusData] = useState([
        {
            name: 'Паспорт региона',
            data: {},
            change_temperature: setTemperature,
            change_temperature_param: setTemperatureParam,
        },
        {
            name: 'Социально-экономические показатели',
            data: {},
            change_temperature: setTemperature,
            change_temperature_param: setTemperatureParam,
        },
        {
            name: 'Избирательные показатели',
            data: {},
            change_temperature: setTemperature,
            change_temperature_param: setTemperatureParam,
        },
        // {
        //     name: 'Банк документов',
        //     data: {},
        //     change_temperature: setTemperature,
        //     change_temperature_param: setTemperatureParam,
        // },
    ]);

    useEffect(() => {
        if (tempMode) {
            if (mapRef.current?.getLayer("places")) {
                mapRef.current.removeLayer("places");
                mapRef.current.removeSource("places");
            }
            if (mapRef.current.getLayer('places-tik')) {
                mapRef.current.removeLayer('places-tik');
                mapRef.current.removeSource('places-tik');
            }
            if (mapRef.current.getLayer('places-uik')) {
                mapRef.current.removeLayer('places-uik');
                mapRef.current.removeSource('places-uik');
            }
        }

        if (!isFirstTempModeChange.current) {
            if (!tempMode) {
                setLevel(levelBeforeTempSwitch.current);
                
                // removing the temp mode
                setTemperature(null);
                if (mapRef.current.getLayer('temperature')) {
                    mapRef.current.removeLayer('temperature');
                    mapRef.current.removeSource('temperature');
                }

                if (mapRef.current.getLayer('elections-results')) {
                    mapRef.current.removeLayer('elections-results');
                    mapRef.current.removeSource('elections-results');
                }

                if (mapRef.current.getLayer("places")) {
                    mapRef.current.removeLayer("places");
                    mapRef.current.removeSource("places");
                }
                if (mapRef.current.getLayer('places-tik')) {
                    mapRef.current.removeLayer('places-tik');
                    mapRef.current.removeSource('places-tik');
                }
                if (mapRef.current.getLayer('places-uik')) {
                    mapRef.current.removeLayer('places-uik');
                    mapRef.current.removeSource('places-uik');
                }
                
                // making previous layers visible (before switching to the temp mode)
                toggleBarsLayersIds.forEach((layerId) => {
                    if (activeMapLayerId.current === layerId) {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
                    } else {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
                    }
                });
                
                if (activeMapLayerId.current === 'municipalities') {
                    mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'visible');
                    mapRef.current.setLayoutProperty('background-muns', 'visibility', 'visible');
                } else {
                    mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
                    mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
                }
                
                if (activeMapLayerId.current === 'municipalities' || activeMapLayerId.current === 'izbirkoms')
                    mapRef.current.setLayoutProperty('background-regions', 'visibility', 'visible');
                else
                    mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
            } else {
                levelBeforeTempSwitch.current = level;
                setLevel(2);
                
                // setting none to all layers visibility
                toggleBarsLayersIds.forEach((layerId) => {
                    if ('regions' === layerId) {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
                    } else {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
                    }
                });
                
                mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
                mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
                mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
                
                // clearing inside layers and filters to them
                for (const layers_group of addingLayerName)
                    for (const key in layers_group)
                        if (mapRef.current.getLayer(layers_group[key]))
                            mapRef.current.removeLayer(layers_group[key]);
                
                mapRef.current.setFilter('districts', null);
                mapRef.current.setFilter('regions', null);
            }
        }
        isFirstTempModeChange.current = false;
    }, [tempMode]);

    useEffect(() => {
        console.log(uiMode);
        if (uiMode === 'election-module' && electionModuleMode === 'commissions-tab') {
            levelBeforeTempSwitch.current = level;
            setLevel(2);

            toggleBarsLayersIds.forEach((layerId) => {
                if ('regions' !== layerId) {
                    mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                    mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
                }
            });
            
            mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
            mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
            mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
            
            // clearing inside layers and filters to them
            for (const layers_group of addingLayerName)
                for (const key in layers_group)
                    if (mapRef.current.getLayer(layers_group[key]))
                        mapRef.current.removeLayer(layers_group[key]);
        }
    }, [uiMode, electionModuleMode])

    useEffect(() => {
        // @ts-ignore
        if (performance.memory) {
            // @ts-ignore
            const memory = performance.memory;
            if (memory.usedJSHeapSize / 1048576 > 2500 || memory.totalJSHeapSize / 1048576 > 2500) { // @ts-ignore
                window.location.assign(window.location.href);
            }
        }
        
    }, [map_render_key, render_key]);

    useEffect(() => setRenderKey(render_key ^ 1), [name, compare_name, popupMode, popupsData, compare_mode, temperature_param]);

    useEffect(() => {
        if (mapRef.current === null) return;
        setMapRenderKey(map_render_key ^ 1);
    }, [popupsData, showType, popupMode]);

    useEffect(() => {
        const set = (resp: string) => {
            const data = JSON.parse(resp);
            let name_found = false, compare_name_found = false;
            for (const el of data) {
                if (el.name === name)
                    name_found = true;
                if (el.name === compare_name)
                    compare_name_found = true;
            }
            
            if (!name_found)
                setName(data[0].name);
            if (!compare_name_found)
                setCompareName(data[0].name);
        }
        let _level = level;
        if (tempMode) _level = 2;
        
        switch (_level) {
            case (0):
                setName('Россия')
                setCompareName('Россия')
                break;
            case (1):
                api.getAllElection()
                    .then(res => setAllElection(res))
                    .catch(error => {
                        console.log(error);
                    });

                api.get_all_districts()
                    .then(resp => set(resp))
                    .catch(error => {
                        console.log(error);
                    });
                break;
            case (2):
                api.get_all_regions()
                    .then(resp => set(resp))
                    .catch(error => {
                        console.log(error);
                    });
                break;
            case (3):
                setName('Пушкино');
                setRegion('Московская область');
                setCompareName('Пушкино');
                setCompareRegion('Московская область');
                break;
            case(4):
                api.get_all_izbirkoms().then(resp => {
                    set(resp);
                });
                break;
        }
    }, [level]);

    let changeNameState = (_name: any) => {
        setName(_name);
    }
    let changeRegionState = (_region: any) => {
        setRegion(_region);
    }
    let changeComparingModeState = () => {
        setCompareMode(!compare_mode);
    }
    let changeComparingNameState = (_compare_name: any) => {
        setCompareName(_compare_name);
    }
    let changeComparingRegionState = (_compare_region: any) => {
        setCompareRegion(_compare_region);
    }
    let changeLevelState = (_level: any) => {
        setLevel(_level);
    }
    let change_temperature = (_temp: any) => {
        setTemperature(_temp);
    }
    let change_show_type = (_type: boolean) => {
        setShowType(_type);
    }
    let change_popups_data = (index: number) => {
        let data = popupsData;
        data ^= (1 << index);
        setPopupsData(data);
    }
    let changeCloseSideMenu = () => {
        setCloseSideMenu(!closeSideMenu)
    }
    let changeOpenMenuState = (index: number, value: boolean) => {
        let state = openMenuState;
        state[index] = value;
        setOpenMenuState(state);
    }
    let changeTempOpenMenu = (index: number) => {
        setTempOpenMenu(index);
    }

    const handleSelectElEvent = async (value: string) => {
        const response = await api.getElection(value);
        setSelectedElectionEventData(response)
    }

    const handleSelectElCandidate = (value: null | string) => {
        setSelectedElectionCandidate(value);
    }

    const hideElectionsPoints = () => {
        if (popup.current) {
            mapRef.current.getCanvas().style.cursor = "";
            popup.current.remove();
            popup.current = null;
        }
        if (electionPointPopup.current) {
            mapRef.current.getCanvas().style.cursor = "";
            electionPointPopup.current.remove();
            electionPointPopup.current = null;
        }
        if (mapRef.current.getLayer("places")) {
            mapRef.current.removeLayer("places");
            mapRef.current.removeSource("places");
        }
        if (mapRef.current.getLayer('places-tik')) {
            mapRef.current.removeLayer('places-tik');
            mapRef.current.removeSource('places-tik');
        }
        if (mapRef.current.getLayer('places-uik')) {
            mapRef.current.removeLayer('places-uik');
            mapRef.current.removeSource('places-uik');
        }
        if (mapRef?.current?.getLayer("elections-results")) {
            mapRef.current.removeLayer("elections-results");
            mapRef.current.removeSource("elections-results");
        }
        if (mapRef.current) {
            toggleBarsLayersIds.forEach((layerId) => {
            if (activeMapLayerId.current === layerId) {
                mapRef.current.setLayoutProperty(
                    layerId,
                    "visibility",
                    "visible",
                );
                mapRef.current.setLayoutProperty(
                    layerId + "-outline",
                    "visibility",
                    "visible",
                );
            } else {
                mapRef.current.setLayoutProperty(layerId, "visibility", "none");
                mapRef.current.setLayoutProperty(
                    layerId + "-outline",
                    "visibility",
                    "none",
                );
            }
        });
    
        if (activeMapLayerId.current === "municipalities") {
            mapRef.current.setLayoutProperty(
                "background-regions-outline",
                "visibility",
                "visible",
            );
            mapRef.current.setLayoutProperty(
                "background-muns",
                "visibility",
                "visible",
            );
        } else {
            mapRef.current.setLayoutProperty(
                "background-regions-outline",
                "visibility",
                "none",
            );
            mapRef.current.setLayoutProperty(
                "background-muns",
                "visibility",
                "none",
            );
          }
    
          if (
            activeMapLayerId.current === "municipalities" ||
            activeMapLayerId.current === "izbirkoms"
          )
            mapRef.current.setLayoutProperty(
                "background-regions",
                "visibility",
                "visible",
            );
          else
            mapRef.current.setLayoutProperty(
                "background-regions",
                "visibility",
                "none",
            );
        }
    
        mapRef.current.setFilter('districts', null);
        mapRef.current.setFilter('regions', null);
        return;
    };

    const handleClickMapUI = () => {
        setUiMode('map');
        setElectionModuleMode('main');
        hideElectionsPoints();
        setPopupMode(false);
        setTempMode(false);
        setIsShowElectionPoints(false);
    };

    const handleClickSelectorModeUI = () => {
        setUiMode('election-module');
        setPopupMode(false);
        setTempMode(false);
        setIsShowElectionPoints(false);
    };

    return (
        <div className="main-container-wrapper">
            <div className="main-menu-toggler">
                <button onClick={handleClickMapUI} className={`main-menu-item ${uiMode === "map" ? "selected-menu-item" : ""}`}>Карта</button>
                <button onClick={handleClickSelectorModeUI} className={`main-menu-item ${uiMode === "election-module" ? "selected-menu-item" : ""}`}>Избирательный модуль</button>
                <div className={`selector-ui-backing ${uiMode === "map" ? "map-backing" : "selector-module-backing"}`} />
            </div>

            {uiMode === "election-module" && electionModuleMode === "commissions-tab" && (
                <ElectionCommissionControllers
                    hideElectionsPoints={hideElectionsPoints}
                    setElectionModuleMode={setElectionModuleMode}
                    mapRef={ mapRef }
                    popup={popup}
                    electionPointPopup={electionPointPopup}
                    activeMapLayerId={activeMapLayerId}
                />
            )}

            {uiMode === "election-module" && (electionModuleMode !== "commissions-tab" && electionModuleMode !== "boundary-map-tab") && (
                <ElectionModule
                    electionModuleMode={electionModuleMode}
                    setElectionModuleMode={setElectionModuleMode}
                    allElection={allElection}
                    setIsLoadingElectionPoints={setIsLoadingElectionPoints}
                    mapRef={mapRef}
                    popup={popup}
                    setIsShowElectionPoints={setIsShowElectionPoints}
                />
            )}

            <div  className="MainContainer">
                {(electionModuleMode !== "commissions-tab" && electionModuleMode !== "boundary-map-tab") && (
                    <div className="side-menu-container" style={{ zIndex: 1000 }}>
                        <SideMenu
                            mapRef={ mapRef }
                            regions_data={ props.all_data === null ? null : props.all_data[2] }
                            temperature_param={ temperature_param }
                            openMenuState={ openMenuState }
                            setOpenMenuState={ changeOpenMenuState }
                            temperature={ temperature }
                            key={ 'SlideMenu' + render_key }
                            name={ name }
                            setName={ setName }
                            region={ region }
                            setRegion={ setRegion }
                            level={ level }
                            compare_name={ compare_name }
                            setCompareName={ setCompareName }
                            setCompareRegion={ setCompareRegion }
                            compare_region={ compare_region }
                            difference={ compare_mode }
                            setTemperature={ change_temperature }
                            last_name={ last_name }
                            last_region={ last_region }
                            setLastName={ setLastName }
                            setLastRegion={ setLastRegion }
                            last_compare_name={ last_compare_name }
                            last_compare_region={ last_compare_region }
                            setLastCompareName={ setLastCompareName }
                            setLastCompareRegion={ setLastCompareRegion }
                            popupMode={ popupMode }
                            setPopupMode={ setPopupMode }
                            popupsData={ popupsData }
                            setPopupsData={ change_popups_data }
                            closeSideMenu={ closeSideMenu }
                            setCloseSideMenu={ changeCloseSideMenu }
                            showType={ showType }
                            setTemperatureParam={ setTemperatureParam }
                            tempMode={ tempMode }
                            setTempMode={ setTempMode }
                            temp_open_menu={ temp_open_menu }
                            setTempOpenMenu={ changeTempOpenMenu }
                            open_menus_data={open_menus_data}
                            setOpenMenusData={setOpenMenusData}
                            allElection={allElection}
                            selectedElectionEvent={selectedElectionEvent}
                            setSelectedElectionEvent={setSelectedElectionEvent}
                            handleSelectElEvent={handleSelectElEvent}
                            selectedElectionEventData={selectedElectionEventData}
                            selectedElectionCandidate={selectedElectionCandidate}
                            handleSelectElCandidate={handleSelectElCandidate}
                            isShowElectionPoints={isShowElectionPoints}
                            setIsShowElectionPoints={setIsShowElectionPoints}
                            activeMapLayerId={activeMapLayerId}
                            isLoadingElectionPoints={isLoadingElectionPoints}
                            setIsLoadingElectionPoints={setIsLoadingElectionPoints}
                            popup={popup}
                        />
                    </div>
                )}

                <MapProject
                    hideElectionsPoints={hideElectionsPoints}
                    setElectionModuleMode={setElectionModuleMode}
                    electionModuleMode={electionModuleMode}
                    mapRef={ mapRef }
                    activeMapLayerId={ activeMapLayerId }
                    key={ 'Map' + map_render_key }
                    level={ level }
                    setName={ changeNameState }
                    setRegion={ changeRegionState }
                    compare_mode={ compare_mode }
                    setCompareName={ changeComparingNameState }
                    setCompareRegion={ changeComparingRegionState }
                    setLevel={ changeLevelState }
                    temperature={ temperature }
                    showType={ showType }
                    popupMode={ popupMode }
                    popupData={ popupsData }
                    setCompareModeMain={ changeComparingModeState }
                    setShowType={ change_show_type }
                    all_data={ props.all_data }
                    setTemperature={ change_temperature }
                    temperature_param={ temperature_param }
                    tempMode={ tempMode }
                    selectedElectionEventData={selectedElectionEventData}
                    selectedElectionCandidate={selectedElectionCandidate}
                    isShowElectionPoints={isShowElectionPoints}
                    popup={popup}
                />
            </div>
        
        </div>
    );
}

export default App;
