import React, { ChangeEvent, Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { Document } from "../../../../types/types";
import "./bank-document-list.scss";
import { DocumentModal } from "../../../document-modal/document-modal";
import { Pagination, Stack } from "@mui/material";
import Loader from "../../../loader";

interface BankDocumentListProps {
  currentDocuments: Document[];
  total: number;
  pageDocuments: number;
  handleChangeDocumentsPage: (_event: ChangeEvent<unknown>, value: number) => Promise<void>;
  isLoading: boolean;
  //
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
  mapRef: any;
  popup: any;
  setIsShowElectionPoints: Dispatch<(prevState: boolean) => undefined>;
  memberName: string;
}

export const BankDocumentList: FC<BankDocumentListProps> = ({ currentDocuments, total, pageDocuments, handleChangeDocumentsPage, isLoading, mapRef, popup, setIsLoadingElectionPoints, setIsShowElectionPoints, memberName }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);

  const handleClickToggleModal = () => {
    setIsOpenModal((prevValue) => !prevValue);
  };

  const handleClickDocument = (item: Document) => {
    setIsOpenModal((prevValue) => !prevValue);
    setSelectedDocument(item);
  };

  const itemsPerPage = 10;

  const totalDocumentsPages = Math.ceil(total / itemsPerPage);

  const ref = useRef();

  return (
    <>
      <p className="bank-document-total-tab">Результатов показано: {total || 0}</p>
      <div style={{ position: "relative", margin: "0 auto 48px", width: "1295px" }}>
        <ul className="bank-document-list-tab">
          {currentDocuments &&
            currentDocuments?.map((item) => {
              return (
                <li className="bank-document-list-tab-item" key={item.id}>
                  <button onClick={() => handleClickDocument(item)} className="document-btn">
                    <p className="bank-document-list-tab-title">{item.title}</p>
                    <p className="bank-document-list-tab-text">{item.news_text}</p>
                  </button>
                </li>
              );
            })}
        </ul>
          {isLoading && (
            <div
              className="loading-backing"
            >
              <div className="loader-container mt-150">
                <Loader ContainerRef={ref.current}  />
              </div>
            </div>
          )}
      </div>
      <div
        className="pagination-wrapper"
      >
        {total > 9 && (
          <Stack spacing={2}>
            <Pagination  count={totalDocumentsPages} page={pageDocuments} onChange={handleChangeDocumentsPage} shape="rounded" />
          </Stack>
        )}      
      </div>
      <DocumentModal
        isOpenModal={isOpenModal}
        handleClickToggleModal={handleClickToggleModal}
        setIsLoadingElectionPoints={setIsLoadingElectionPoints}
        mapRef={mapRef}
        popup={popup}
        // commissionMemberData={commissionMemberData}
        setIsShowElectionPoints={setIsShowElectionPoints}
        selectedDocument={selectedDocument}
        searchValue={memberName}
      />
    </>
  );
};
