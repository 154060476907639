import "./election-controller.scss";

import geobuf from "geobuf";
import Pbf from "pbf";
import React, { Dispatch, FC, SetStateAction, useRef } from "react";
import mapboxgl from 'mapbox-gl';

import { setTemperature, toggleBarsLayersIds } from "../../../../models/utils";
import { Election, ElectionEventData } from "../../../../types/types";

const getNormalizeName = (value: string) => {
  if (
    value.includes("Санкт-Петербург") ||
    value.includes("Севастополь") ||
    value.includes("Москва")
  ) {
    return `г. ${value}`;
  }
  if (value.toLowerCase() === "чувашская республика") {
    return "Чувашская Республика";
  }
  if (value.toLowerCase() === "удмуртская республика") {
    return "Удмуртская Республика"
  }
  if (value.toLowerCase().includes('югра')) {
    return "Ханты-Мансийский автономный округ — Югра";
  }
  if (
    value === "Адыгея" ||
    value === "Башкортостан" ||
    value === "Бурятия" ||
    value === "Дагестан" ||
    value === "Ингушетия" ||
    value === "Калмыкия" ||
    value === "Марий Эл" ||
    value === "Мордовия" ||
    value === "Саха (Якутия)" ||
    value === "Северная Осетия-Алания" ||
    value === "Татарстан" ||
    value === "Тыва" ||
    value === "Хакасия"
  ) {
    return `Республика ${value}`;
  }
  return value;
};

const prepareTempData = (
  name: string,
  data: ElectionEventData,
  candidate: string,
) => {
  const result = data.regions.reduce((acc, item) => {
    const value = item.results.find((res) => res.name === candidate);
    return {
      ...acc,
      [getNormalizeName(item.region)]: value?.percentage
        ? value?.percentage * 100
        : value?.value,
    };
  }, {});
  return result;
};

const getValueByRegion = (regionName?: string, data?: ElectionEventData) => {
  if (regionName && data) {
    const findRegion = data.regions
      .find((item) => getNormalizeName(item.region) === regionName);

    const filtered = findRegion?.results?.filter((item) => !item.name.startsWith('Число'));
    return filtered;
  }
}

interface ElectionControllerProps {
  mapRef: any;
  regions_data: any;

  data: any;
  level: number;
  change_temperature: any;
  change_temperature_param: any;
  temp_mode: boolean;
  temperature_param: string;
  temp_open_menu: number | string;
  setTempOpenMenu: any;

  allElection: Election[];
  selectedElectionEvent: string;
  setSelectedElectionEvent: Dispatch<SetStateAction<string>>;
  handleSelectElEvent: (value: string) => void;
  selectedElectionEventData: ElectionEventData;
  selectedElectionCandidate: string;
  handleSelectElCandidate: (value: string) => void;
  nameRegion?: string;
}

export const ElectionController: FC<ElectionControllerProps> = (props) => {
  const radioDivChosenStyle = {
    background: "#2a5cdc",
  };

  const popup = useRef(null);

  const handleShowResult = () => {
    const temp_data = prepareTempData(
      props.selectedElectionEvent,
      props.selectedElectionEventData,
      props.selectedElectionCandidate,
    );
    let geojson_data = geobuf.decode(new Pbf(props.regions_data));
    geojson_data = setTemperature(temp_data, geojson_data);

    // hiding previous layers
    if (props.mapRef.current.getLayer("temperature")) {
      props.mapRef.current.removeLayer("temperature");
      props.mapRef.current.removeSource("temperature");
    }
    if (props?.mapRef?.current?.getLayer("places")) {
      props.mapRef.current.removeLayer("places");
      props.mapRef.current.removeSource("places");
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      props.mapRef.current.removeSource("elections-results");
    }
    toggleBarsLayersIds.forEach((layerId) => {
      props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
      props.mapRef.current.setLayoutProperty(
        layerId + "-outline",
        "visibility",
        "none",
      );
    });
    props.mapRef.current.setLayoutProperty(
      "regions-outline",
      "visibility",
      "none",
    );

    //adding temperature layer
    props.mapRef.current.addSource("elections-results", {
      type: "geojson",
      data: geojson_data,
    });
    props.mapRef.current.addLayer({
      id: "elections-results",
      type: "fill",
      source: "elections-results",
      layout: {},
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": 0.5,
      },
    });

    props.mapRef.current.on('mousemove', 'elections-results', (e) => {
      // const findRegion = props.selectedElectionEventData.regions.find((item) => getNormalizeName(item.region) === e.features[0].properties.name);
      // const findCandidate = findRegion?.results?.find((item) => item?.name === props.selectedElectionCandidate)
      if (popup.current != null) {
        popup.current.setLngLat(e.lngLat);
        popup.current.setHTML(`<h3>${e.features[0].properties.name}</h3>`);
        // popup.current.setHTML(`<h3>${e.features[0].properties.name}</h3>${findCandidate ? `<h4>result: ${findCandidate.percentage}%</h4>` : ''}`);
      }
    })

    props.mapRef.current.on('mouseenter', 'elections-results', (e) => {
      props.mapRef.current.getCanvas().style.cursor = 'pointer';
      if (popup.current === null) {
          popup.current = new mapboxgl.Popup({
              closeOnClick: false,
              closeButton: false,
          })
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(props.mapRef.current);
        }
        popup.current
          .setLngLat(e.lngLat)
          .setHTML(`
            <h3>${e.features[0].properties.name}</h3>
          `)
          .addTo(props.mapRef.current);
    });

    props.mapRef.current.on('mouseleave', 'elections-results', () => {
      props.mapRef.current.getCanvas().style.cursor = '';
      if (popup.current) {
        popup.current.remove();
      }
      popup.current = null;
    });

    // setting temperature name
    props.change_temperature(temp_data);
    props.change_temperature_param(props.selectedElectionEvent);
  };

  const items = props.selectedElectionEventData && props.selectedElectionEventData.regions[0].results
    ?.filter((item) => !item.name.startsWith('Число'));

  return (
    <div>
      <p className="title">Результаты выборов и явка</p>
      <p className="subtitle">Выберите год выборов и партию:</p>
      <div className="container">
        <div className="little-moving-block">
          <input
            type="radio"
            name="lmb-checkbox"
            id="election-type"
            checked={ props.temp_open_menu === 'год выборов' }
            onChange={() => props.setTempOpenMenu('год выборов')}
            style={{ height: "50px" }}
          />
          <label htmlFor="election-type" className="lmb-header">
            <p>Год выборов</p>
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.05687 -4.25131e-05L15.365 7.54541L12.3676 7.54541L7.99898 1.97723L8.1655 2.03405L7.7443 2.03405L7.92063 1.97723L3.54215 7.54541L0.554621 7.54541L6.86274 -4.2705e-05L9.05687 -4.25131e-05Z"
                fill="black"
              />
            </svg>
          </label>
          {props.allElection &&
            props.allElection.map((item) => (
              <div className="lmb-content" key={item.name}>
                <div
                  className="lbm-div"
                  onClick={() => {
                    props.setSelectedElectionEvent(item.name);
                    props.handleSelectElEvent(item.name);
                    props.handleSelectElCandidate(null);
                  }}
                >
                  <div className="radio-div">
                    <span
                      style={{
                        ...(item.name === props.selectedElectionEvent &&
                          radioDivChosenStyle),
                      }}
                    />
                  </div>
                  <p className={"lbm-line"}>{item.name}</p>
                </div>
              </div>
            ))}
        </div>

        <div className="little-moving-block">
          <input
            type="radio"
            name="lmb-checkbox"
            id="party"
            checked={ props.temp_open_menu === 'партию' }
            onChange={() => props.setTempOpenMenu('партию')}
            style={{ height: "50px" }}
          />
          <label htmlFor="party" className="lmb-header">
            <p>Партия</p>
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.05687 -4.25131e-05L15.365 7.54541L12.3676 7.54541L7.99898 1.97723L8.1655 2.03405L7.7443 2.03405L7.92063 1.97723L3.54215 7.54541L0.554621 7.54541L6.86274 -4.2705e-05L9.05687 -4.25131e-05Z"
                fill="black"
              />
            </svg>
          </label>
          {props.temp_mode && items && (
            <>
              {items.map(
                (item) => (
                  <div className="lmb-content" key={item.name}>
                    <div
                      className="lbm-div"
                      onClick={() => {
                        if (!props.temp_mode) return;
                        props.handleSelectElCandidate(item.name);
                      }}
                    >
                      {!props.temp_mode ? null : (
                        <div className="radio-div">
                          <span
                            style={{
                              ...(item.name ===
                                props.selectedElectionCandidate &&
                                radioDivChosenStyle),
                            }}
                          />
                        </div>
                      )}
                      <p className={"lbm-line"}>{item.name}</p>
                    </div>
                  </div>
                ),
              )}
            </>
          )}
          {!props.temp_mode && props.nameRegion && props.selectedElectionEventData && (
            <>
              {getValueByRegion(props.nameRegion, props.selectedElectionEventData)?.map((item) => (
                <div className="lmb-content" key={item.name}>
                  <div
                    className="lbm-div"
                    onClick={() => {
                      if (!props.temp_mode) return;
                      props.handleSelectElCandidate(item.name);
                    }}
                  >
                    {!props.temp_mode ? null : (
                      <div className="radio-div">
                        <span
                          style={{
                            ...(item.name ===
                              props.selectedElectionCandidate &&
                              radioDivChosenStyle),
                          }}
                        />
                      </div>
                    )}
                    <p className={"lbm-line"}>{item.name}: <span>{item.percentage ? item.percentage : item.value}</span></p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {props.temp_mode && (
        <button disabled={!props.selectedElectionCandidate} className="SideMenuContent__button" onClick={handleShowResult}>
          Показать результаты
        </button>
      )}
    </div>
  );
};
