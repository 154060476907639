import React, { Dispatch, SetStateAction } from "react";
import { LittleOpenMenu } from "../";
import './extra-data-menu.scss'
import { Election, ElectionEventData } from "../../types/types";
import ElectionController from "./components/election-controller";

interface ExtraDataProp {
    mapRef: any;
    regions_data: any;
    
    data: any;
    level: number;
    change_temperature: any;
    change_temperature_param: any;
    temp_mode: boolean;
    temperature_param: string;
    temp_open_menu: number | string;
    setTempOpenMenu: any;
    allElection: Election[];
    selectedElectionEvent: string;
    setSelectedElectionEvent: Dispatch<SetStateAction<string>>;
    handleSelectElEvent: (value: string) => void;
    selectedElectionEventData: ElectionEventData;
    selectedElectionCandidate: string;
    handleSelectElCandidate: (value: string | null) => void;
    nameRegion?: string;
    popup: any;
}

const ExtraDataMenu = (props: ExtraDataProp) => {
    return (
        <section style={ { display: 'flex', flexDirection: 'column', gap: '10px' } }>
            <p>Выберите показатель, чтобы просмотреть тепловую карту</p>
            <LittleOpenMenu
                mapRef={ props.mapRef }
                regions_data={ props.regions_data }
                temperature_param={ props.temperature_param }
                data={props.data}
                difference={ false }
                level={ props.level }
                change_temperature={ props.change_temperature }
                change_temperature_param={ props.change_temperature_param }
                temp_mode={ props.temp_mode }
                temp_open_menu={props.temp_open_menu}
                setTempOpenMenu={props.setTempOpenMenu}
                popup={props.popup}
            />
            <ElectionController {...props} />
        </section>
    )
}

export default ExtraDataMenu;
