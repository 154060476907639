// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-data-description {
  font-size: 20px;
  font-weight: 300;
  line-height: 24.2px;
  text-align: center;
  color: #989898;
  margin: auto;
  padding-top: 160px;
  width: 479px;
}`, "",{"version":3,"sources":["webpack://./src/components/no-data-description/no-data-description.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":[".no-data-description {\n  font-size: 20px;\n  font-weight: 300;\n  line-height: 24.2px;\n  text-align: center;\n  color: #989898;\n  margin: auto;\n  padding-top: 160px;\n  width: 479px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
