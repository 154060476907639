// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.election-commission-back-btn {
  top: 37px;
  left: 151px;
}

.election-commission-controllers-container {
  position: absolute;
  z-index: 10;
  top: 37px;
  right: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.election-commission-inner-container {
  width: 364px;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.election-commission-inner-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #333333;
}

.input-inner-container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.text-center {
  padding: 12px;
  text-align: center;
}

.selected-text {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/boundary-map-controllers/boundary-map-controllers.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".election-commission-back-btn {\n  top: 37px;\n  left: 151px;\n}\n\n.election-commission-controllers-container {\n  position: absolute;\n  z-index: 10;\n  top: 37px;\n  right: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.election-commission-inner-container {\n  width: 364px;\n  border-radius: 6px;\n  background-color: #FFFFFF;\n}\n\n.election-commission-inner-text {\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15.73px;\n  color: #333333;\n}\n\n.input-inner-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 12px;\n}\n\n.text-center {\n  padding: 12px;\n  text-align: center;\n}\n\n.selected-text {\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
