// stuff imports
import React, { Dispatch, SetStateAction } from "react";
import { ExtraDataMenu, OpenMenu, SvgCross } from "../";
import Api from "../../api/api";
import './side-menu-content.scss'
import { popupData } from "../../models/popupData";
import { Election, ElectionEventData } from "../../types/types";

interface SideMenuContentProps {
    name: string;
    level: number;
    showType: boolean;
    temperature_param: string;
    
    compare_name?: string;
    difference: boolean;
    
    setTemperature: any;
    setTemperatureParam: any;
    temp_open_menu: number | string;
    setTempOpenMenu: any;
    
    popupMode: boolean;
    setPopupMode: any;
    popupsData: number;
    setPopupsData: any;
    
    closeSideMenu: boolean;
    setCloseSideMenu: any;
    
    openMenuState: boolean[];
    setOpenMenuState: any;
    
    tempMode: boolean;
    setTempMode: any;
    
    open_menus_data: any[];
    difference_data: any;
    
    mapRef: any;
    regions_data: any;
    allElection: Election[];
    selectedElectionEvent: string;
    setSelectedElectionEvent: Dispatch<SetStateAction<string>>;
    handleSelectElEvent: (value: string) => void;
    selectedElectionEventData: ElectionEventData;
    selectedElectionCandidate: string;
    handleSelectElCandidate: (value: string | null) => void;
    isShowElectionPoints: boolean;
    setIsShowElectionPoints: Dispatch<SetStateAction<boolean>>;
    activeMapLayerId: any;
    isLoadingElectionPoints: boolean;
    setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
    popup: any;
}

const SideMenuContent = (props: SideMenuContentProps) => {
    let SideMenuContainerRef = React.createRef<HTMLDivElement>();
    let SideMenuContentRef = React.createRef<HTMLDivElement>();
    let SideMenuHeaderRef = React.createRef<HTMLParagraphElement>();
    let SideMenuHeaderContainerRef = React.createRef<HTMLDivElement>();
    let SideMenuButtonsContainer = React.createRef<HTMLDivElement>();
    
    const render = () => {
        const changeInput = (index: number) => {
            props.setPopupsData(index);
        }
        
        const sideMenuContentStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuHeaderStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuButtonsContainerStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuHeaderContainerStyle = {
            justifyContent: 'flex-end'
        };
        const sideMenuContainerStyle = {
            minWidth: 'calc(32px * 2 + 40px)',
            maxWidth: 'calc(32px * 2 + 40px)'
        };

        const getMainContent = () => {
            if (props.tempMode && props.level === 2)
                return <>
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p className="bold-blue-text">{ props.name }</p>
                        { props.name === undefined ? <p>выберите область</p> : null }
                    </div>
                    
                    <ExtraDataMenu
                        mapRef={ props.mapRef }
                        regions_data={ props.regions_data }
                        temperature_param={ props.temperature_param }
                        temp_mode={ props.tempMode }
                        data={ props.open_menus_data[1].data }
                        level={ props.level }
                        change_temperature={ props.setTemperature }
                        change_temperature_param={ props.setTemperatureParam }
                        temp_open_menu={ props.temp_open_menu }
                        setTempOpenMenu={ props.setTempOpenMenu }
                        allElection={props.allElection}
                        selectedElectionEvent={props.selectedElectionEvent}
                        setSelectedElectionEvent={props.setSelectedElectionEvent}
                        handleSelectElEvent={props.handleSelectElEvent}
                        selectedElectionEventData={props.selectedElectionEventData}
                        selectedElectionCandidate={props.selectedElectionCandidate}
                        handleSelectElCandidate={props.handleSelectElCandidate}
                        popup={props.popup}
                    />
                </>
            else if (props.tempMode)
                return <>
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p className="bold-blue-text">{ props.name }</p>
                        { props.name === undefined ? <p>выберите область</p> : null }
                    </div>
                    
                    <p>Выберите уровень регионов чтобы построить тепловую карту</p>
                </>
            if (props.popupMode)
                return <>
                    <div style={ { ...(props.closeSideMenu && sideMenuContentStyle) } }
                         className="flex-col gap-10">
                        
                        { !props.showType
                            ? <>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((props.popupsData & (1 << 0)) === (1 << 0)) }
                                           onChange={ () => changeInput(0) }></input>
                                    <p>{ popupData[0].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((props.popupsData & (1 << 1)) === (1 << 1)) }
                                           onChange={ () => changeInput(1) }></input>
                                    <p>{ popupData[1].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((props.popupsData & (1 << 2)) === (1 << 2)) }
                                           onChange={ () => changeInput(2) }></input>
                                    <p>{ popupData[2].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((props.popupsData & (1 << 3)) === (1 << 3)) }
                                           onChange={ () => changeInput(3) }></input>
                                    <p>{ popupData[3].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((props.popupsData & (1 << 4)) === (1 << 4)) }
                                           onChange={ () => changeInput(4) }></input>
                                    <p>{ popupData[4].ru }</p>
                                </div>
                            </>
                            : <div className="flex-row gap-20">
                                <input type={ "checkbox" }
                                       checked={ ((props.popupsData & (1 << 0)) === (1 << 0)) }
                                       onChange={ () => changeInput(0) }></input>
                                <p>Численность избирателей</p>
                            </div>
                        }
                    
                    </div>
                </>
            return <>
                <div style={ { ...(props.closeSideMenu && sideMenuContentStyle) } }
                     className="SideMenuContent__content">
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p>{ props.name }</p>
                        { props.name === undefined ? <p>выберите область</p> : null }
                        
                        {
                            props.difference ?
                                <div>
                                    <p>В сравнении с</p>
                                    <p className="light-blue">{ props.compare_name }</p>
                                </div>
                                : null
                        }
                    </div>
                    
                    { props.open_menus_data.map((data, index) => {
                        if (props.level === 3 && index === 2) return null;
                        if (props.level === 4 && (index === 0 || index === 1)) return null;
                        return <OpenMenu
                            mapRef={ props.mapRef }
                            regions_data={ props.regions_data }
                            openMenuState={ props.openMenuState[index] }
                            setOpenMenuState={ props.setOpenMenuState }
                            openMenuIndex={ index }
                            key={ 'OpenMenu' + index }
                            name={ data.name }
                            data={ data.data }
                            difference_data={ props.difference_data }
                            difference={ props.difference }
                            change_temperature={ props.setTemperature }
                            change_temperature_param={ props.setTemperatureParam }
                            level={ props.level }
                            temp_open_menu={ props.temp_open_menu }
                            setTempOpenMenu={ props.setTempOpenMenu }
                            temperature_param={ props.temperature_param }
                            allElection={props.allElection}
                            selectedElectionEvent={props.selectedElectionEvent}
                            setSelectedElectionEvent={props.setSelectedElectionEvent}
                            handleSelectElEvent={props.handleSelectElEvent}
                            selectedElectionEventData={props.selectedElectionEventData}
                            selectedElectionCandidate={props.selectedElectionCandidate}
                            handleSelectElCandidate={props.handleSelectElCandidate}
                            nameRegion={props.name}
                            isShowElectionPoints={props.isShowElectionPoints}
                            setIsShowElectionPoints={props.setIsShowElectionPoints}
                            activeMapLayerId={props.activeMapLayerId}
                            isLoadingElectionPoints={props.isLoadingElectionPoints}
                            setIsLoadingElectionPoints={props.setIsLoadingElectionPoints}
                            popup={props.popup}
                        />
                    }) }
                    
                    { (props.level === 1 || props.level === 2)
                        ? <button className="SideMenuContent__button" onClick={ async () => {
                            const api = new Api();
                            if (props.difference)
                                window.open(`${ api.base_url }${ props.level === 1 ? 'district' : 'region' }/presentation/?name=${ props.name }&name2=${ props.compare_name }`);
                            else
                                window.open(`${ api.base_url }${ props.level === 1 ? 'district' : 'region' }/presentation/?name=${ props.name }`)
                        } }>Скачать презентацию</button>
                        : null }
                </div>
            </>
        }
        
        return (
            <div style={ { ...(props.closeSideMenu && sideMenuContainerStyle) } } ref={ SideMenuContainerRef }
                 className="SideMenuContent">
                <div style={ { ...(props.closeSideMenu && sideMenuHeaderContainerStyle) } }
                     ref={ SideMenuHeaderContainerRef }
                     className="flex-row align-center just-space w-full float-right">
                    <p style={ { ...(props.closeSideMenu && sideMenuHeaderStyle) } } ref={ SideMenuHeaderRef }
                       className="SideMenuHeader">Показатели</p>
                    
                    <SvgCross
                        key={ 'svg-cross' + props.closeSideMenu }
                        SideMenuContainerRef={ SideMenuContainerRef }
                        SideMenuContentRef={ SideMenuContentRef }
                        SideMenuHeaderRef={ SideMenuHeaderRef }
                        SideMenuHeaderContainerRef={ SideMenuHeaderContainerRef }
                        SideMenuButtonsContainer={ SideMenuButtonsContainer }
                        setCloseSideMenu={ props.setCloseSideMenu }
                        closeSideMenu={ props.closeSideMenu }
                    />
                </div>
                
                { !props.difference
                    ? <section style={ { ...(props.closeSideMenu && sideMenuButtonsContainerStyle) } }
                               ref={ SideMenuButtonsContainer } className="radio-input">
                        <label>
                            <input value="value-1" name="value-radio" id="value-1" type="radio"
                                   checked={ !props.popupMode && !props.tempMode } onChange={ () => {
                                props.setPopupMode(false);
                                props.setTempMode(false);
                                props.setIsShowElectionPoints(false);
                            } }/>
                            <span>Таблица</span>
                        </label>
                        {/* <label>
                            <input value="value-2" name="value-radio" id="value-2" type="radio"
                                   checked={ props.popupMode } onChange={ () => {
                                props.setTempMode(false);
                                props.setPopupMode(true);
                            } }/>
                            <span>Карта</span>
                        </label> */}
                        <label>
                            <input value="value-2" name="value-radio" id="value-2" type="radio"
                                   checked={ props.tempMode } onChange={ () => {
                                props.setPopupMode(false);
                                props.setTempMode(true);
                                props.setIsShowElectionPoints(false);
                            } }/>
                            <span>Тепловая карта</span>
                        </label>
                        <span className="selection"></span>
                    </section>
                    : null }
                
                <div id="SideMenuContent" style={ { ...(props.closeSideMenu && sideMenuContentStyle)} } ref={SideMenuContentRef}>{ getMainContent() }</div>
            </div>
        );
    }
    
    return render();
}

export default SideMenuContent;
