import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './style.scss';
import Api from "./api/api";
import { Loader } from "./components";


const root = ReactDOM.createRoot(document.getElementById('root'));

const RenderApp = React.memo(() => {
    const LoaderRef = useRef(null);
    const [render_key, setRenderKey] = useState(0);
    const [all_data, setAllData] = useState(null);
    const api = new Api();
    
    // load all data from api
    const loadApiData = async () => {
        let res = [null, null, null, null, null, null];
        
        res[0] = await api.get_country_geojson();
        res[1] = await api.get_districts_geojson();
        res[2] = await api.get_regions_geojson();
        res[3] = await api.get_municipalities_geojson();
        res[4] = await api.get_izbirkom_geojson();
        res[5] = await api.get_division_geojson();
        
        setAllData(res);
    }
    
    useEffect(() => {
        if (all_data === null)
            loadApiData().then();
        else {
            setRenderKey(render_key ^ 1);
            LoaderRef.current.style.display = 'none';
        }
    }, [all_data]);
    
    return (
        <>
            <Loader
                ContainerRef={LoaderRef}
            />
            <App
                key={ 'main-app' + render_key }
                all_data={ all_data }
            />
        </>
    )
});

root.render(
    // <React.StrictMode>
    <RenderApp/>
    // </React.StrictMode>
);

export { default as App } from './app'
