import "./show-elections-commissions.scss";

import mapboxgl from "mapbox-gl";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
} from "react";

import Api from "../../api/api";
import { addingLayerName, toggleBarsLayersIds } from "../../models/utils";
import { Election, ElectionEventData } from "../../types/types";

interface ElectionControllerProps {
  mapRef: any;
  regions_data: any;

  data: any;
  level: number;
  change_temperature: any;
  change_temperature_param: any;
  temp_mode: boolean;
  temperature_param: string;
  temp_open_menu: number | string;
  setTempOpenMenu: any;

  allElection: Election[];
  selectedElectionEvent: string;
  setSelectedElectionEvent: Dispatch<SetStateAction<string>>;
  handleSelectElEvent: (value: string) => void;
  selectedElectionEventData: ElectionEventData;
  selectedElectionCandidate: string;
  handleSelectElCandidate: (value: string | null) => void;
  nameRegion?: string;
  isShowElectionPoints: boolean;
  setIsShowElectionPoints: Dispatch<SetStateAction<boolean>>;
  activeMapLayerId: any;
  isLoadingElectionPoints: boolean;
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
}

export const ShowElectionsCommissions: FC<ElectionControllerProps> = (
  props,
) => {
  // const [isLoading, setIsLoading] = useState(false);
  const api = new Api();

  const popup = useRef(null);
  const popupOnHover = useRef(null);

  const handleShowResult = useCallback(async () => {
    // setIsLoading(true);
    props.setIsLoadingElectionPoints(true);
    const res = await api.getElectionCommissions();
    if (props?.mapRef?.current?.getLayer("temperature")) {
      props.mapRef.current.removeLayer("temperature");
      props.mapRef.current.removeSource("temperature");
    }
    if (props?.mapRef?.current?.getLayer("places")) {
      props.mapRef.current.removeLayer("places");
      props.mapRef.current.removeSource("places");
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      props.mapRef.current.removeSource("elections-results");
    }
    for (const layers_group of addingLayerName)
      for (const key in layers_group)
          if (props.mapRef.current.getLayer(layers_group[key]))
              props.mapRef.current.removeLayer(layers_group[key]);

    toggleBarsLayersIds.forEach((layerId) => {
      props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
      props.mapRef.current.setLayoutProperty(
        layerId + "-outline",
        "visibility",
        "none",
      );
    });
    props.mapRef.current.setLayoutProperty(
      "regions-outline",
      "visibility",
      "none",
    );

    const features = res.map((item) => {
      return {
        type: "Feature",
        properties: {
          name: `${item.name}`,
        },
        geometry: {
          type: "Point",
          coordinates: item.coords.split(", ").reverse(),
        },
      };
    });

    props.mapRef.current.addSource("places", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    props.mapRef.current.addLayer({
      id: "places",
      type: "circle",
      source: "places",
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": 6,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });

    props.mapRef.current.on("click", "places", async (e) => {
      e.preventDefault();
      const res = await api.getElectionCommission(
        e.features[0].properties.name,
      );
      if (popup.current) {
        props.mapRef.current.getCanvas().style.cursor = "";
        popup.current.remove();
        popup.current = null;
      }
      popup.current = new mapboxgl.Popup({
        closeOnClick: true,
        closeButton: true,
        closeOnMove: false,
      })
        .setLngLat(e.lngLat)
        .addTo(props.mapRef.current);
      popup.current.setHTML(`
            <div class="popup-list-container container">
              <p><span class="strong">Имя:</span> ${res.name}</p>
              <p><span class="strong">Адрес:</span> ${res.address}</p>
              <p><span class="strong">Телефонный номер:</span> ${res.phone_number}</p>
              <p><span class="strong">Срок окончания полномочий:</span> ${res.too}</p>
              <p><span class="strong">Состав комиссии:</span></p>
              <ul>
                ${res.members.map((item) => `<li>${item.name}</li>`).join(" ")}
              </ul>
            </div>
            `);
    });

    props.mapRef.current.on('mousemove', 'places', (e) => {
      if (popupOnHover.current != null) {
        popupOnHover.current.setLngLat(e.lngLat);
        popupOnHover.current.setHTML(`<h3>${e.features[0].properties.name}</h3>`);
      }
    })

    props.mapRef.current.on('mouseenter', 'places', (e) => {
      props.mapRef.current.getCanvas().style.cursor = 'pointer';
      if (popupOnHover.current === null) {
          popupOnHover.current = new mapboxgl.Popup({
              closeOnClick: false,
              closeButton: false,
          })
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(props.mapRef.current);
        }
    });
    props.mapRef.current.on('mouseleave', 'places', () => {
      props.mapRef.current.getCanvas().style.cursor = '';
      popupOnHover.current.remove();
      popupOnHover.current = null;
    });
    // setIsLoading(false);
    props.setIsLoadingElectionPoints(false);
  }, [api, props.mapRef]);

  const handleHide = useCallback(() => {
    if (popup.current) {
      props.mapRef.current.getCanvas().style.cursor = "";
      popup.current.remove();
      popup.current = null;
    }
    if (props?.mapRef?.current?.getLayer("places")) {
      props.mapRef.current.removeLayer("places");
      props.mapRef.current.removeSource("places");
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      props.mapRef.current.removeSource("elections-results");
    }
    if (props.mapRef.current) {
      toggleBarsLayersIds.forEach((layerId) => {
        if (props.activeMapLayerId.current === layerId) {
          props.mapRef.current.setLayoutProperty(
            layerId,
            "visibility",
            "visible",
          );
          props.mapRef.current.setLayoutProperty(
            layerId + "-outline",
            "visibility",
            "visible",
          );
        } else {
          props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
          props.mapRef.current.setLayoutProperty(
            layerId + "-outline",
            "visibility",
            "none",
          );
        }
      });

      if (props.activeMapLayerId.current === "municipalities") {
        props.mapRef.current.setLayoutProperty(
          "background-regions-outline",
          "visibility",
          "visible",
        );
        props.mapRef.current.setLayoutProperty(
          "background-muns",
          "visibility",
          "visible",
        );
      } else {
        props.mapRef.current.setLayoutProperty(
          "background-regions-outline",
          "visibility",
          "none",
        );
        props.mapRef.current.setLayoutProperty(
          "background-muns",
          "visibility",
          "none",
        );
      }

      if (
        props.activeMapLayerId.current === "municipalities" ||
        props.activeMapLayerId.current === "izbirkoms"
      )
        props.mapRef.current.setLayoutProperty(
          "background-regions",
          "visibility",
          "visible",
        );
      else
        props.mapRef.current.setLayoutProperty(
          "background-regions",
          "visibility",
          "none",
        );
    }

    props.mapRef.current.setFilter('districts', null);
    props.mapRef.current.setFilter('regions', null);
    return;
  }, [props.mapRef, popup, props.activeMapLayerId]);

  const handleChange = () => {
    props.setIsShowElectionPoints((prevValue) => {
      const currentValue = !prevValue;
      if (currentValue === true) {
        handleShowResult();
      }
      if (currentValue === false) {
        handleHide();
      }
      return currentValue;
    });
  };

  return (
    <div>
      <div className="input">
        <input
          // disabled={isLoading}
          disabled={props.isLoadingElectionPoints}
          onChange={handleChange}
          checked={
            props.isShowElectionPoints === undefined
              ? false
              : props.isShowElectionPoints
          }
          type={"checkbox"}
        />
        <p>отображение избирательных участков</p>
      </div>
    </div>
  );
};
