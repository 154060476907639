export const popupData = [
    {
        en: 'people',
        ru: 'Население',
        count: 'чел',
    },
    {
        en: 'square',
        ru: 'Площадь',
        count: 'га',
    },
    {
        en: 'migration',
        ru: 'Миграция населения',
        count: 'чел',
    },
    {
        en: 'household',
        ru: 'Размер домохозяйств',
        count: 'чел',
    },
    {
        en: 'birth',
        ru: 'Рождаемость',
        count: 'на тысячу чел',
    },
]