// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-wrapper {
  position: relative;
}

.back-btn-left {
  left: 73px;
}

.bank-document-module-selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 57px;
}

.election-main-selector-year {
  width: 156px !important;
}

.mt-150 {
  margin-top: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/bank-documents-tab/bank-documents-tab.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".title-wrapper {\n  position: relative;\n}\n\n.back-btn-left {\n  left: 73px;\n}\n\n.bank-document-module-selector-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 57px;\n}\n\n.election-main-selector-year {\n  width: 156px !important;\n}\n\n.mt-150 {\n  margin-top: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
