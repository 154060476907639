import React, { FC } from "react";

export const SVGIcon: FC<{
  name: string;
  width: string;
  height: string;
  onClick?: () => void;
  className?: string;
}> = ({ name, onClick, className, width, height }) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    style={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width,
      height,
    }}
    onClick={onClick}
  >
    <use href={`/images/iconsSprite.svg#${name}`} />
  </svg>
);
