import React, { FC, useRef } from "react";
import { extraDataRowsNames } from "../../../../models/table-data-params";
import Pbf from "pbf";
import geobuf from "geobuf";
import mapboxgl from 'mapbox-gl';
import Api from "../../../../api/api";
import { setTemperature, toggleBarsLayersIds } from "../../../../models/utils";

interface ItemProps {
    mapRef: any;
    regions_data: any;
    data: any;
    difference?: any;
    level: number;
    change_temperature: any;
    temperature_param: string;
    change_temperature_param: any;
    temp_mode: boolean
    temp_open_menu: any;
    setTempOpenMenu: any;
    categoryId: string;
    popup: any;
    item: {
        name: string;
        value: string;
        id: string;
    }
}

const round = (number: number) => {
    return Math.round(number * 100) / 100;
}

export const Item: FC<ItemProps> = (props) => {
    let radioDivChosenStyle = {
        background: '#2a5cdc',
    }
    // const popup = useRef(null);

    const getTemperature = async (name: string) => {
        if (!props.temp_mode) return;
        // getting temp data from api
        const [cat, param] = getCatParam(name);
        const api = new Api();
        const temp_data = JSON.parse(await api.get_region_temperature(cat, param))
        
        // getting geojson data
        let geojson_data = geobuf.decode(new Pbf(props.regions_data))
        geojson_data = setTemperature(temp_data, geojson_data);
        
        // hiding previous layers
        if (props.mapRef.current.getLayer('temperature')) {
            props.mapRef.current.removeLayer('temperature');
            props.mapRef.current.removeSource('temperature');
        }
        if (props.mapRef.current.getLayer('places-tik')) {
            props.mapRef.current.removeLayer('places-tik');
            props.mapRef.current.removeSource('places-tik');
        }
        if (props.mapRef.current.getLayer('places-uik')) {
            props.mapRef.current.removeLayer('places-uik');
            props.mapRef.current.removeSource('places-uik');
        }
        if (props?.mapRef?.current?.getLayer("places")) {
            props.mapRef.current.removeLayer("places");
            props.mapRef.current.removeSource("places");
        }
        if (props?.mapRef?.current?.getLayer("elections-results")) {
            props.mapRef.current.removeLayer("elections-results");
            props.mapRef.current.removeSource("elections-results");
        }
        toggleBarsLayersIds.forEach((layerId) => {
            props.mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
            props.mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
        });
        props.mapRef.current.setLayoutProperty('regions-outline', 'visibility', 'none');
        
        //adding temperature layer
        props.mapRef.current.addSource('temperature', {
            type: 'geojson',
            data: geojson_data
        })
        props.mapRef.current.addLayer({
            id: 'temperature',
            type: 'fill',
            source: 'temperature',
            layout: {},
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.5
            },
        })

        props.mapRef.current.on('mousemove', 'temperature', (e) => {
            const currentValue = ParseElCount(round(temp_data[e.features[0].properties.name]), props.item.value);
            if (props.popup.current != null) {
              props.popup.current.setLngLat(e.lngLat);
              props.popup.current.setHTML(`<h3>${e.features[0].properties.name} </br> ${props.item.name}: ${currentValue}</h3>`);
            }
          })
      
          props.mapRef.current.on('mouseenter', 'temperature', (e) => {
            props.mapRef.current.getCanvas().style.cursor = 'pointer';
            if (props.popup.current === null) {
                props.popup.current = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                })
                    .setLngLat(e.lngLat)
                    .setHTML(e.features[0].properties.name)
                    .addTo(props.mapRef.current);
              }
          });
      
        //   props.mapRef.current.on('mouseleave', 'temperature', () => {
        //     props.mapRef.current.getCanvas().style.cursor = '';
        //     props.popup.current.remove();
        //     props.popup.current = null;
        //   });
        
        // setting temperature name
        props.change_temperature(temp_data);
        props.change_temperature_param(name);
  }

    const ParseElCount = (count?: number, value?: string) => {
        if (isNaN(count)) return 0;
        if (!count) return 0;
        if (value === '%') {
            return count === 0 ? `${count}%` : `${ Math.round((count - 100) * 100) / 100 }%`;
        }
        return `${count} ${value}`;
    }

    const getCatParam = (name: string) => {
        for (const cat in extraDataRowsNames)
            for (const param in extraDataRowsNames[cat]) {
                if (extraDataRowsNames[cat][param].name === name) {
                    return [cat, param];
                }
            }
    }

    return (
        <div className="lmb-content">
            <div
                className="lbm-div"
                onClick={() => {
                    getTemperature(props.item.name);
                }}
            >
                { !props.temp_mode ? null :
                    <div className="radio-div">
                        <span style={ { ...(props.temperature_param === props.item.name && radioDivChosenStyle) } }/>
                    </div>
                }
                <p className={ "lbm-line" }>
                    { props.item.name }:{' '}
                    <span style={{ 'cursor': 'pointer' }}>
                        {`${ParseElCount(round(props?.data?.extra?.[props.categoryId]?.[props.item.id]), props.item.value)}`}
                    </span>
                </p>
            </div>
        </div>
    );
};
