// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopMenuContainer {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 6px;
  background: white;
  padding: 12px;
  gap: 24px;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.03);
}
.TopMenuContainer p {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  color: #000;
}
.TopMenuContainer input {
  width: 16px;
  height: 16px;
}
.TopMenuContainer input:not(:checked) ~ p {
  color: #535353 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/top-menu/top-menu.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,SAAA;EACA,2CAAA;AACF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;AAGI;EACE,yBAAA;AADN","sourcesContent":[".TopMenuContainer {\n  width: fit-content;\n  height: fit-content;\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  border-radius: 6px;\n  background: white;\n  padding: 12px;\n  gap: 24px;\n  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.03);\n\n\n  p {\n    font-size: 15px;\n    font-weight: 500;\n    line-height: 16px;\n    color: #000;\n  }\n\n  input {\n    width: 16px;\n    height: 16px;\n\n    &:not(:checked) ~ p {\n      color: #535353 !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
