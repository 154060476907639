// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button {
  width: 251px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24.2px;
  color: #FFFFFF;
  border: none;
  text-align: center;
  background-color: #2A5CDC;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.031372549);
}

.primary-button:hover {
  opacity: 0.8;
}

.disabled-primary-button {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.disabled-primary-button:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/primary-button/primary-button.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,sDAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".primary-button {\n  width: 251px;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 54px;\n  font-size: 20px;\n  font-weight: 300;\n  line-height: 24.2px;\n  color: #FFFFFF;\n  border: none;\n  text-align: center;\n  background-color: #2A5CDC;\n  box-shadow: 0px 0px 7px 2px #00000008;\n}\n\n.primary-button:hover {\n  opacity: 0.8;\n}\n\n.disabled-primary-button {\n  background-color: #d9d9d9;\n  cursor: not-allowed;\n}\n\n.disabled-primary-button:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
