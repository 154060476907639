import React, { FC } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import "./selector.scss";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const theme = createTheme({
    components: {
        MuiSelect: {
            defaultProps: {
                IconComponent: ExpandMoreIcon,
            },
            styleOverrides: {
                root: {
                    '.MuiSvgIcon-root': {
                        color: '#000000',
                        width: '28px',
                        height: '28px'
                    },
                    '.MuiInputBase-root': {
                      borderRadius: '20px'
                    },
                },
            },
        },
    },
});

interface SelectorProps {
  menuList: { name: string | number, value: string | number }[];
  value: string | number;
  onChange: (event: string | number) => void;
  className?: string;
  disabled?: boolean;
}

export const Selector: FC<SelectorProps> = ({ menuList, onChange, value, className, disabled = false }) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Select
        disabled={disabled}
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label', style: { borderRadius: '10px' } }}
        className={`main-selector ${className}`}
    >
      {menuList.map((item) => (
        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
      ))}
      </Select>
    </ThemeProvider>
  );
};
