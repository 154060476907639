export const CountriesColors = {
    'Дальневосточный федеральный округ': '#FF7DF2',
    'Сибирский федеральный округ': '#73FF71',
    'Уральский федеральный округ': '#c4b1f9',
    "Приволжский федеральный округ": '#A0D2FF',
    "Южный федеральный округ": '#092eb1',
    "Центральный федеральный округ": '#7674e4',
    "Северо-Кавказский федеральный округ": '#08ffe1',
    "Северо-Западный федеральный округ": '#ffbbcb',
    "Российская Федерация": '#ffbbcb',
}

export const RegionParent =
    {
        "Московская область": "Центральный федеральный округ",
        "Воронежская область": "Центральный федеральный округ",
        "Самарская область": "Приволжский федеральный округ",
        "Орловская область": "Центральный федеральный округ",
        "Тульская область": "Центральный федеральный округ",
        "Липецкая область": "Центральный федеральный округ",
        "Владимирская область": "Центральный федеральный округ",
        "Костромская область": "Центральный федеральный округ",
        "Ивановская область": "Центральный федеральный округ",
        "Калужская область": "Центральный федеральный округ",
        "Смоленская область": "Центральный федеральный округ",
        "Курская область": "Центральный федеральный округ",
        "Свердловская область": "Уральский федеральный округ",
        "Тамбовская область": "Центральный федеральный округ",
        "Нижегородская область": "Приволжский федеральный округ",
        "Челябинская область": "Уральский федеральный округ",
        "Республика Мордовия": "Приволжский федеральный округ",
        "Белгородская область": "Центральный федеральный округ",
        "Брянская область": "Центральный федеральный округ",
        "Ярославская область": "Центральный федеральный округ",
        "Чувашская Республика": "Приволжский федеральный округ",
        "Республика Башкортостан": "Приволжский федеральный округ",
        "Республика Татарстан": "Приволжский федеральный округ",
        "Пензенская область": "Приволжский федеральный округ",
        "Ульяновская область": "Приволжский федеральный округ",
        "Волгоградская область": "Южный федеральный округ",
        "Оренбургская область": "Приволжский федеральный округ",
        "Рязанская область": "Центральный федеральный округ",
        "Ростовская область": "Южный федеральный округ",
        "Саратовская область": "Приволжский федеральный округ",
        "Тверская область": "Центральный федеральный округ",
        "Мурманская область": "Северо-Западный федеральный округ",
        "Республика Карелия": "Северо-Западный федеральный округ",
        "Сахалинская область": "Дальневосточный федеральный округ",
        "Санкт-Петербург": "Северо-Западный федеральный округ",
        "г. Санкт-Петербург": "Северо-Западный федеральный округ",
        "Республика Адыгея": "Южный федеральный округ",
        "Республика Ингушетия": "Северо-Кавказский федеральный округ",
        "Ненецкий автономный округ": "Северо-Западный федеральный округ",
        "Амурская область": "Дальневосточный федеральный округ",
        "Республика Бурятия": "Дальневосточный федеральный округ",
        "Республика Тыва": "Сибирский федеральный округ",
        "Алтайский край": "Сибирский федеральный округ",
        "Еврейская автономная область": "Дальневосточный федеральный округ",
        "Чукотский автономный округ": "Дальневосточный федеральный округ",
        "Забайкальский край": "Дальневосточный федеральный округ",
        "Псковская область": "Северо-Западный федеральный округ",
        "Иркутская область": "Сибирский федеральный округ",
        "Республика Алтай": "Сибирский федеральный округ",
        "Тюменская область": "Уральский федеральный округ",
        "Республика Саха (Якутия)": "Дальневосточный федеральный округ",
        "Республика Коми": "Северо-Западный федеральный округ",
        "Ямало-Ненецкий автономный округ": "Уральский федеральный округ",
        "Республика Хакасия": "Сибирский федеральный округ",
        "Новосибирская область": "Сибирский федеральный округ",
        "Омская область": "Сибирский федеральный округ",
        "Кабардино-Балкарская Республика": "Северо-Кавказский федеральный округ",
        "Карачаево-Черкесская Республика": "Северо-Кавказский федеральный округ",
        "Пермский край": "Приволжский федеральный округ",
        "Республика Марий Эл": "Приволжский федеральный округ",
        "Вологодская область": "Северо-Западный федеральный округ",
        "Кировская область": "Приволжский федеральный округ",
        "Москва": "Центральный федеральный округ",
        "г. Москва": "Центральный федеральный округ",
        "Камчатский край": "Дальневосточный федеральный округ",
        "Курганская область": "Уральский федеральный округ",
        "Чеченская Республика": "Северо-Кавказский федеральный округ",
        "Ханты-Мансийский автономный округ — Югра": "Уральский федеральный округ",
        "Ханты-Мансийский автономный округ - Югра": "Уральский федеральный округ",
        "Ставропольский край": "Северо-Кавказский федеральный округ",
        "Республика Северная Осетия-Алания": "Северо-Кавказский федеральный округ",
        "Новгородская область": "Северо-Западный федеральный округ",
        "Красноярский край": "Сибирский федеральный округ",
        "Хабаровский край": "Дальневосточный федеральный округ",
        "Удмуртская Республика": "Приволжский федеральный округ",
        "Ленинградская область": "Северо-Западный федеральный округ",
        "Республика Калмыкия": "Южный федеральный округ",
        "Магаданская область": "Дальневосточный федеральный округ",
        "Астраханская область": "Южный федеральный округ",
        "Калининградская область": "Северо-Западный федеральный округ",
        "Архангельская область": "Северо-Западный федеральный округ",
        "Приморский край": "Дальневосточный федеральный округ",
        "Республика Дагестан": "Северо-Кавказский федеральный округ",
        "Краснодарский край": "Южный федеральный округ",
        "Томская область": "Сибирский федеральный округ",
        "Кемеровская область": "Сибирский федеральный округ",
        "Республика Крым": "Южный федеральный округ",
        "Севастополь": "Южный федеральный округ",
        "г. Севастополь": "Южный федеральный округ",
    }