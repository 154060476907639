// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-selector {
  width: 290px;
  border-radius: 10px !important;
  border: none;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.031372549);
  background: #FFFFFF;
}

.main-selector fieldset {
  border: none;
}

.selector-input-module-icon {
  padding-right: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/selector/selector.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,8BAAA;EACA,YAAA;EACA,sDAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".main-selector {\n  width: 290px;\n  border-radius: 10px !important;\n  border: none;\n  box-shadow: 0px 0px 7px 2px #00000008;\n  background: #FFFFFF;\n}\n\n.main-selector fieldset {\n  border: none;\n}\n\n.selector-input-module-icon {\n  padding-right: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
