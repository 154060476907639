import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Document } from '../../types/types';
import Modal from './components/modal';

interface DocumentModalProps {
  handleClickToggleModal: () => void;
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
  mapRef: any;
  popup: any;
  // commissionMemberData: CommissionMemberData;
  setIsShowElectionPoints: Dispatch<SetStateAction<boolean>>;
  selectedDocument: Document;
  searchValue?: string;
  isOpenModal: boolean;
}

export const DocumentModal: FC<DocumentModalProps> = (props) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#document-modal');
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted && ref.current && props.isOpenModal
    ? createPortal(<Modal { ...props } />, ref.current)
    : null;
};
