// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CrossMenuContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 1000px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  padding-right: 50px;
  cursor: pointer;
}
.CrossMenuContainer .CrossMenuContent {
  --container-size: 60px;
  width: var(--container-size);
  height: var(--container-size);
  transition-duration: 0.3s;
  position: relative;
}
.CrossMenuContainer .line {
  --line-width: 18px;
  --line-height: 3px;
  width: var(--line-width);
  height: var(--line-height);
  top: 50%;
  left: 50%;
  background: #2a5cdc;
  position: absolute;
}
.CrossMenuContainer .line:nth-child(1) {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}
.CrossMenuContainer .line:nth-child(2) {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

input, button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/cross-menu/cross-menu.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,sBAAA;EACA,4BAAA;EACA,6BAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;EACA,kBAAA;EAEA,wBAAA;EACA,0BAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACE,yDAAA;AADN;AAII;EACE,0DAAA;AAFN;;AAOA;EACE,eAAA;AAJF","sourcesContent":[".CrossMenuContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  font-size: 15px;\n  align-items: center;\n  justify-content: center;\n  background: white;\n  border-radius: 1000px;\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  z-index: 3;\n  padding-right: 50px;\n  cursor: pointer;\n\n  .CrossMenuContent {\n    --container-size: 60px;\n    width: var(--container-size);\n    height: var(--container-size);\n    transition-duration: .3s;\n    position: relative;\n  }\n\n  .line {\n    --line-width: 18px;\n    --line-height: 3px;\n\n    width: var(--line-width);\n    height: var(--line-height);\n    top: 50%;\n    left: 50%;\n    background: #2a5cdc;\n    position: absolute;\n\n    &:nth-child(1) {\n      transform: translateX(-50%) translateY(-50%) rotate(0deg);\n    }\n\n    &:nth-child(2) {\n      transform: translateX(-50%) translateY(-50%) rotate(90deg);\n    }\n  }\n}\n\ninput,button {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
