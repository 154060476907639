// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-input {
  width: 290px;
  height: 54px;
  padding: 0 32px 0 32px;
  border-radius: 10px !important;
  border: none;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.031372549);
  background: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/input/input.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,sDAAA;EACA,mBAAA;AACF","sourcesContent":[".main-input {\n  width: 290px;\n  height: 54px;\n  padding: 0 32px 0 32px;\n  border-radius: 10px !important;\n  border: none;\n  box-shadow: 0px 0px 7px 2px #00000008;\n  background: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
